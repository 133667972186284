import React from "react";
import "../styles/landingpage.css";
export default function LandingPage(props) {
  const landingPageItem = (
    <div className="landing-page--container" id="landing">
      <div className="landing-page--top-text">
        <div className="landing-page--brown-risd">
          <h3 className="blue-text">BROWN-RISD</h3>
          <h3 className="blue-text">GENDO TAIKO</h3>
        </div>

        <h3 className="blue-text">PRESENTS</h3>
      </div>

      <img
        className="landing-page--logo"
        src="/images/logos/ectc-logo.png"
        alt="test"
      ></img>
      <div className="landing-page--bottom-text">
        <h3 className="blue-text">2.16 - 2.18</h3>
        <h3 className="blue-text">PROVIDENCE, RI</h3>
      </div>

      <h2 className="enter-arrow" onClick={props.onEnter}>
        ENTER
      </h2>

      {/* <div className="mock-1"></div>
      <div className="mock-2"></div>
      <div className="mock-3"></div>
      <div className="mock-4"></div> */}
    </div>
  );

  return <div>{landingPageItem}</div>;
}
