export const sponsors = [
  {
    id: "0",
    name: "Asano Taiko U.S.",
    img: "/images/sponsors/Asano.jpg",
    desc: [],
    link: "https://asano.us/",
  },
  {
    id: "1",
    name: "Odaiko New England",
    img: "/images/sponsors/ONE.png",
    desc: [],
    link: "https://onetaiko.org/web/",
  },
  {
    id: "2",
    name: "TaikoVentures",
    img: "/images/logos/ectc-logo-diamond-text.png",
    desc: [],
    link: "https://taikoventures.com/",
  },
  {
    id: "3",
    name: "Taiko Community Alliance (TCA)",
    img: "images/sponsors/TCA-Logo_stacked.png",
    desc: [],
    link: "https://www.taikocommunityalliance.org/",
  },
  {
    id: "4",
    name: "Taiko with Toni",
    img: "/images/logos/ectc-logo-diamond-text.png",
    desc: [],
    link: "https://taikowithtoni.com/",
  },
  {
    id: "5",
    name: "Brown Arts Institute",
    img: "/images/sponsors/BAI-logo.png",
    desc: [],
    link: "https://arts.brown.edu/",
  },
];

export const vendors = [
  {
    name: "Asano Taiko U.S.",
    img: "/images/sponsors/Asano.jpg",
    link: "https://asano.us/",
  },
  {
    name: "kaDON",
    img: "/images/sponsors/kadon.png",
    link: "https://kadon-103184.square.site/",
  },
  // {
  //   name: "RAW Taiko",
  //   img: "/images/sponsors/raw.png",
  //   link: "https://www.rawtaiko.ca/",
  // },
  {
    name: "Chieko Kojima",
    img: "/images/logos/ectc-logo-diamond-text.png",
  },
  {
    name: "Gendo Taiko",
    img: "/images/logos/gendo-logo-black.png",
  },
  {
    name: "Finding HerBeat",
    img: "/images/sponsors/finding-her-beat.png",
  },
];
