import React from "react";
import Header from "../components/Header";
// import SponsorsSticky from "../components/SponsorsSticky";
import Footer from "../components/Footer";

export default function About() {
  return (
    <div className="about">
      <Header />
      <div className="page">
        <div className="chappa-L-container">
          <img
            className="chappa-L"
            src="/images/assets/illust_chappaL.png"
            alt="test"
          ></img>
        </div>
        <div className="chappa-R-container">
          <img
            className="chappa-R"
            src="/images/assets/illust_chappaR.png"
            alt="test"
          ></img>
        </div>
        <div className="about--page">
          <img src="/images/ectc-2017.jpg" alt="ECTC 2017"></img>
          <div className="about--text">
            <h2>What is ECTC?</h2>
            <p>
              Since 2011, the East Coast Taiko Conference has fostered
              intergenerational knowledge of taiko performance and engagement.
              Hosted annually by a roster of Northeast collegiate taiko groups,
              the conference offers participants the opportunity to expand their
              repertoire, train with professional taiko players, and build
              connections throughout the community. Brown/RISD Gendo Taiko is
              honored to host ECTC in 2024, the first time the conference will
              take place since the pandemic.
            </p>
            <p>
              This years’ theme, <i>In Return</i>, emphasizes the future of
              taiko not only as a performing art but also a form of cultural
              expression and community-engaged pedagogy. As we emerge from the
              pandemic, ECTC works to reignite and revitalize critically-needed
              relationships between taiko groups.{" "}
            </p>
          </div>

          <div className="about--text">
            <h2>What will happen at ECTC 2024?</h2>
            <p>
              Over the course of three days, participants will have the
              opportunity to attend 3 workshops with 16 professional taiko
              performers. Workshops will cover a vast array of subjects, from
              the fundamentals of taiko drumming, to body awareness, to
              traditional makeup and dance.{" "}
            </p>
            <p>
              ECTC will also be a space for educational enrichment. There will
              be a public screening of Finding HerBeat, a documentary on
              sisterhood among female and non-binary taiko players across North
              America and Japan, as well as open-forum discussions related to
              taiko and identity.{" "}
            </p>
            <p>
              The conference will conclude with a final concert, highlighting
              the diverse performance styles and talents of performing
              individuals and groups.
            </p>
          </div>

          <div className="about--text">
            <h2>Who is Gendo Taiko?</h2>
            <p>
              Gendo Taiko is a contemporary Taiko drumming ensemble centered
              around celebrating diasporic Asian identities. Consisting of
              students from Brown University and the Rhode Island School of
              Design, the group plays across New England for events ranging from
              musical festivals to small gatherings.
            </p>
            <p>
              As one of the earliest collegiate Taiko drumming groups
              established on the East Coast, Gendo Taiko (initially dubbed Brown
              Taiko) was founded at Brown University in the Fall of 2004 by
              Raiki Machida (Brown ’07) and Joshua Goldner (Brown ’05). Gendo
              Taiko’s earliest songs came from Wadaiko Gensou (和太鼓 幻創), a
              semi-professional Taiko duo based in Miyage, Japan, with whom
              Machida had trained. In the summer of 2006, Goldner and Machida
              hosted Wadaiko Gensou members Takao Handa and Satoko Nambu at
              Brown University to expand the group’s repertoire, hone its
              members’ techniques, and build a new set of drums.
            </p>
            <p>
              As the group grew in membership, it also found its own musical
              style and produced unique song arrangements. Over the years, Gendo
              Taiko has adopted a formal audition process, developed a Group
              Independent Study Project (GISP) to explore the history of Taiko,
              and is now creating a well-structured program of study for its
              members. Today, Gendo Taiko continues to expand its repertoire
              through new compositions and collaborates with the greater Taiko
              community by hosting and participating in annual conferences and
              invitationals. Gendo Taiko is proud to be located in the
              Providence community and seeks to use its platform to celebrate
              the Asian diaspora.
            </p>
          </div>

          <img
            src="/images/gendo-silly.jpg"
            alt="Gendo at our 2023 photoshoot"
          ></img>
        </div>
      </div>

      {/* <SponsorsSticky /> */}
      <Footer isHome={true} />
    </div>
  );
}
