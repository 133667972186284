import React from "react";
import { useLocation } from "react-router-dom";
import NavbarItem from "./NavbarItem";
import "../styles/headernavbar.css";
import { navData } from "../data/PostData";

export default function Navbar(props) {
  // let navigate = useNavigate();
  let location = useLocation();
  const currPath = location.pathname;

  function closeNav() {
    document.getElementById("navbar").style.animation =
      "close-nav 0.4s forwards";
    document.getElementById("navbar-gray").style.display = "none";
  }

  const navbarItems = navData.map((data, index) => {
    let pathConcat = data.name === "home" ? "/" : "/" + currPath;
    return (
      <NavbarItem
        key={index}
        name={data.name}
        path={pathConcat}
        closeNav={closeNav}
        // sub={data.subPages}
        // subPaths={data.subPageLinks}
      />
      // <div
      //   key={index}
      //   className={
      //     pathConcat === currPath ? "navbar--option-curr" : "navbar--option"
      //   }
      //   onClick={() => {
      //     if (pageName === "home") {
      //       return navigate("/");
      //     }
      //     navigate(`/${pageName}`);
      //   }}
      // >
      //   <h3 className="navbar--option-text">{pageName.toUpperCase()}</h3>
      // </div>
    );
  });

  const [hover, setHover] = React.useState("false");

  return (
    <div className="navbar--container" id="navbar">
      <div
        className="navbar--arrow"
        onClick={closeNav}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <img
          className="arrow"
          alt="back arrow icon"
          src={`/images/back_arrow${hover ? "_hover" : ""}.png`}
        />
      </div>
      <div className="navbar-options--container">{navbarItems}</div>
    </div>
  );
}
