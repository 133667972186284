export const schedule = [
  {
    id: 0,
    day: "FRIDAY, February 16th",
    // might need a subevents for odaiko thing (e.g.)
    events: [
      {
        id: 0,
        time: "4:00pm - 10:00pm",
        name: "Registration Check-In",
        location:
          "Stephen Robert '62 Campus Center, Kasper Multipurpose Room 040",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },
      // {
      //   id: 1,
      //   time: "5:00pm - 7:00pm",
      //   name: "Opening Jam",
      //   location: "Salomon Center DECI",
      // },
      {
        id: 1,
        time: "5:00pm - 10:00pm",
        name: "Marketplace",
        location: "Granoff Center for the Creative Arts, Englander Studio N420",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
      {
        id: 2,
        time: "7:00pm - 9:30pm",
        name: "Finding Her Beat Screening & Panel Discussion",
        location:
          "Granoff Center for the Creative Arts, Martinos Auditorium 110",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
    ],
  },
  {
    id: 1,
    day: "SATURDAY, February 17th",
    events: [
      {
        id: "yuh",
        time: "7:00am - 6:30pm",
        name: "Marketplace",
        location: "Granoff Center for the Creative Arts, Englander Studio N420",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
      {
        id: 0,
        time: "7:00am - 8:00am",
        name: "Registration Check-In",
        location: "MacMillan Hall 117",
        link: "https://www.google.com/maps/place/Macmillan+Hall,+167+Thayer+St,+Providence,+RI+02912/@41.8260189,-71.402667,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453b5fc51075:0x50d665fa3a267150!8m2!3d41.8261169!4d-71.4001574!16s%2Fg%2F11b8tcvgwk?entry=ttu",
      },

      {
        id: 1,
        time: "8:00am - 9:00am",
        name: "Opening Ceremony",
        location: "MacMillan Hall 117",
        link: "https://www.google.com/maps/place/Macmillan+Hall,+167+Thayer+St,+Providence,+RI+02912/@41.8260189,-71.402667,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453b5fc51075:0x50d665fa3a267150!8m2!3d41.8261169!4d-71.4001574!16s%2Fg%2F11b8tcvgwk?entry=ttu",
      },
      {
        id: 2,
        time: "9:30am - 12:00pm",
        name: "Workshop 1",
        location: "Various Locations",
        link: "/workshops",
      },
      {
        id: 3,
        time: "12:00pm - 1:30pm",
        name: "Lunch (provided)",
        location:
          "Stephen Robert '62 Campus Center, Kasper Multipurpose Room 040",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },
      {
        id: 4,
        time: "1:30pm - 2:15pm",
        name: "Taiko Talks",
        location: "Various Locations",
        link: "/workshops",
      },
      {
        id: 5,
        time: "2:30pm - 5:00pm",
        name: "Workshop 2",
        location: "Various Locations",
        link: "/workshops",
      },
      // {
      //   id: 6,
      //   time: "5:00pm - 6:30pm",
      //   name: "Dinner (NOT provided)",
      //   location: "TBD",
      // },
      {
        id: 7,
        time: "7:00pm - 9:30pm",
        name: "Final Concert",
        location: "Lindemann Performing Arts Center: Main Hall L1",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },
      {
        id: 8,
        time: "10:30pm - 11:30pm",
        name: "Community Meeting",
        location:
          "Stephen Robert '62 Campus Center, Kasper Multipurpose Room 040",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },
    ],
  },
  {
    id: 2,
    day: "SUNDAY, February 18th",
    events: [
      {
        id: "yuhhhh",
        time: "7:00am - 2:00pm",
        name: "Marketplace",
        location: "Granoff Center for the Creative Arts, Englander Studio N420",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
      {
        id: 0,
        time: "7:00am - 8:00am",
        name: "Breakfast (provided)",
        location:
          "Stephen Robert '62 Campus Center, Kasper Multipurpose Room 040",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },

      {
        id: 1,
        time: "8:30am - 11:00am",
        name: "Workshop 3",
        location: "Various Locations",
        link: "/workshops",
      },
      {
        id: 2,
        time: "12:00pm - 1:30pm",
        name: "Closing Ceremony & Photo",
        location: "MacMillan Hall 117",
        link: "https://www.google.com/maps/place/Macmillan+Hall,+167+Thayer+St,+Providence,+RI+02912/@41.8260189,-71.402667,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453b5fc51075:0x50d665fa3a267150!8m2!3d41.8261169!4d-71.4001574!16s%2Fg%2F11b8tcvgwk?entry=ttu",
      },
    ],
  },
];
