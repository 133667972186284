import React from "react";
import Header from "../components/Header";
// import SponsorsSticky from "../components/SponsorsSticky";
import Footer from "../components/Footer";
// import Announcement from "../components/Announcement";
import "../styles/workshops.css";
import { workshops, leaders, taikoTalkData } from "../data/WorkshopData";
import SponsorsCard from "../components/SponsorsCard";
import WorkshopCard from "../components/WorkshopCard";
// import Card from "../components/Card";
import TaikoTalk from "../components/TaikoTalk";

export default function Workshops() {
  const [display, setDisplay] = React.useState(0);

  const workshopCards = workshops.map((session, index) => {
    const sessionWorkshops = session.workshops.map((data, index) => {
      return (
        <WorkshopCard
          key={index}
          title={data.title}
          person={data.person}
          body={data.body}
          level={data.level}
          location={data.location}
          link={data.link}
        />
      );
    });
    return (
      <div key={index} className="day-block">
        <h1 className="ws--session-text">Session {session.session}</h1>
        {sessionWorkshops}
      </div>
    );
  });
  // const workshopCards = workshops[0].workshops.map((data, index) => {
  // return (
  //   <WorkshopCard
  //     key={index}
  //     title={data.title}
  //     person={data.person}
  //     body={data.body}
  //     level={data.level}
  //   />
  // );
  // });

  const workshopPage = (
    <div className="workshops--container">{workshopCards}</div>
  );

  const leaderCards = leaders.map((data, index) => {
    return (
      <SponsorsCard
        key={index}
        img={data.img}
        name={data.confirmed === "yes" ? data.name : "To be announced..."}
        desc={data.description}
      />
    );
  });

  const taikoTalkCards = taikoTalkData.map((data, index) => {
    return (
      <TaikoTalk
        key={index}
        name={data.name}
        img={data.img}
        bio={data.bio}
        title={data.title}
        desc={data.description}
        location={data.location}
        link={data.link}
      />
    );
  });

  const taikoTalkPage = <div className="tts--container">{taikoTalkCards}</div>;

  // const workshopPage = (
  //   <div className="workshop--container">{workshopCards}</div>
  // );
  // const workshopPage = (
  //   <div className="workshop--container">
  //     <Card
  //       name="This page is under construction."
  //       body={[
  //         "Feel free to look through our workshop leaders by clicking the `Workshop Leaders` button!",
  //       ]}
  //     />
  //   </div>
  // );
  const leadersPage = (
    <div className="sponsors-cards--container">{leaderCards}</div>
  );

  function displayPage() {
    if (display === 0) {
      return workshopPage;
    } else if (display === 1) {
      return leadersPage;
    } else {
      return taikoTalkPage;
    }
  }

  return (
    <div>
      <Header />
      <div className="page">
        {/* <h2>Welcome to the Workshops page!</h2> */}
        {/* <p>This page is under construction.</p> */}
        <div className="chappa-L-container">
          <img
            className="chappa-L"
            src="/images/assets/illust_chappaL.png"
            alt="test"
          ></img>
        </div>

        <div className="chappa-R-container">
          <img
            className="chappa-R"
            src="/images/assets/illust_chappaR.png"
            alt="test"
          ></img>
        </div>

        <div className="kane-container">
          <img
            className="kane"
            src="/images/assets/illust_kaneL.png"
            alt="test"
          ></img>
        </div>

        <div className="flowerCombo-container">
          <img
            className="flowerCombo"
            src="/images/assets/illust_flowerCombo.png"
            alt="test"
          ></img>
        </div>

        <div className="flowerHappi-container">
          <img
            className="flowerHappi"
            src="/images/assets/illust_flowerHappi.png"
            alt="test"
          ></img>
        </div>

        <div className="chuBlue-container">
          <img
            className="chuBlue"
            src="/images/assets/illust_chuBlue.png"
            alt="test"
          />
        </div>

        <div className="ws--button-container">
          <button
            className={`ws--button${display === 0 ? "-select" : ""}`}
            onClick={() => setDisplay(0)}
          >
            Workshop Information
          </button>
          <button
            className={`ws--button${display === 1 ? "-select" : ""}`}
            onClick={() => setDisplay(1)}
          >
            Workshop Leaders
          </button>
          <button
            className={`ws--button${display === 2 ? "-select" : ""}`}
            onClick={() => setDisplay(2)}
          >
            Taiko Talk
          </button>
        </div>

        {displayPage()}
      </div>
      {/* <SponsorsSticky /> */}
      <Footer isHome={true} />
      {/* <Footer isHome={displayWorkshops ? false : true} /> */}
    </div>
  );
}
