import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReactMarkdown from "react-markdown";
import "../styles/accomodations.css";
// import SponsorsSticky from "../components/SponsorsSticky";

export default function FAQ() {
  const components = {
    a: ({ node, ...props }) => (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    ),
  };
  return (
    <div className="accomodations">
      <Header />
      <div className="page">
        <div className="chappa-L-container">
          <img
            className="chappa-L"
            src="/images/assets/illust_chappaL.png"
            alt="test"
          ></img>
        </div>
        <div className="chappa-R-container">
          <img
            className="chappa-R"
            src="/images/assets/illust_chappaR.png"
            alt="test"
          ></img>
        </div>
        <div className="about--page">
          <h1>Frequently Asked Questions</h1>
          <div className="about--text">
            <h3>
              I believe I registered for the conference already. Might there be
              a chance that the records indicate that I am not registered
              because I used a different email address this year to register?
            </h3>
            <p>
              Yes, if you have already registered you should have received a
              Brown University Order Confirmation receipt. It is likely that if
              you received the previous email telling you to register that you
              used different emails between previous ECTCs and this upcoming
              one. We are going off previous years emails so there might be some
              folks who have registered with different emails but are the same
              person.
            </p>
          </div>

          <div className="about--text">
            <h3>Are there conference specific hotel registration discounts?</h3>
            <ReactMarkdown components={components}>
              Because Gendo Taiko is a student organization under Brown
              University, there are a number of guidelines we must adhere to.
              One of these guidelines states that we are not allowed to sign
              contracts with hotels to create block reservations or courtesy
              reservations. However, on the [accomodations
              page](/accomodations), you will be able to find hotels that are
              nearby and encourage registerants to consult the hotel and lodging
              tab online for more information.
            </ReactMarkdown>
          </div>

          <div className="about--text">
            <h3>
              What is the schedule for the three days? How many workshops,
              panels, and talks am I allowed to sign up for?
            </h3>
            <ReactMarkdown components={components}>
              While specifics regarding locations for workshops and panels are
              to be determined, you can find a rough schedule for our conference
              on the [schedule page](/schedule) Everyone who has registered for
              the conference will soon receive a Google form where they will be
              able to rank their top choices for workshops. We will do our best
              to meet everyone's top choices. People are allowed to attend up to
              three different workshops. All panels, Taiko Talks, film
              screenings, concerts, and gathering times are also included within
              the conference registration fee. Some meals will be provided on
              certain days as outlined by the schedule. T-shirts and other
              merchandise pre-ordered or purchased at the conference are an
              additional cost.
            </ReactMarkdown>
          </div>
        </div>
      </div>

      <Footer isHome={true} />
    </div>
  );
}
