import React from "react";
import "../styles/card.css";
import ReactMarkdown from "react-markdown";

export default function Card(props) {
  const components = {
    a: ({ node, ...props }) => (
      <a
        className="common-link"
        href={props.href}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </a>
    ),
    ul: ({ node, ...props }) => <ul className="my--ul">{props.children}</ul>,
    img: ({ node, ...props }) => (
      <img className="card--img" alt="test" src={props.src}></img>
    ),
  };

  const bodyItems = props.body?.map((text, index) => {
    if (index === 0) {
      return (
        <div key={index}>
          <ReactMarkdown components={components}>{text}</ReactMarkdown>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <p className="break"></p>
          <ReactMarkdown components={components}>{text}</ReactMarkdown>
        </div>
      );
    }
  });

  return (
    <div className="card--container">
      <h2 className="card--title">
        <ReactMarkdown components={components}>{props.name}</ReactMarkdown>
      </h2>
      {/* <h2 className="card--title">{props.name}</h2> */}

      <div className="card--body-items">{bodyItems}</div>
    </div>
  );
}

// const linkItems = props.links?.map((data, index) => {
//   return (
//     <a key={index} href={data.href} target="_blank" rel="noreferrer">
//       {data.text}
//     </a>
//   );
// });
// const bodyItem = (
//   <div>
//     <p
//       dangerouslySetInnerHTML={{
//         __html: text,
//         // __html: props.body.replace(/\n/g, "<br />"),
//       }}
//     >
//       {/* {props.body} */}
//     </p>
//     {props.links && linkItems}
//   </div>
// );
