import React from "react";
// import { useNavigate } from "react-router-dom";

export default function SponsorsCard(props) {
  // let navigate = useNavigate();

  const bodyItems = props.desc?.map((text, index) => {
    if (index === 0) {
      return (
        <div key={index}>
          <p key={index}>{text}</p>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <p className="break"></p>
          <p>{text}</p>
        </div>
      );
    }
  });

  return (
    <div className="sponsors-card--container">
      <div className="sponsors-card--img-container">
        <a href={props.link} target="_blank" rel="noreferrer">
          <img src={props.img} alt="test"></img>
        </a>
      </div>

      <div className="sponsors-card--text">
        <h2>{props.name}</h2>
        {/* <p>{props.desc}</p> */}
        {bodyItems}
      </div>
    </div>
  );
}
