import React from "react";
import Header from "../components/Header";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import { posts } from "../data/PostData";
import LandingPage from "../components/LandingPage";
// import SponsorsDiv from "../components/SponsorsDiv";
// import SponsorsInner from "../components/SponsorsInner";
// import SponsorsSticky from "../components/SponsorsSticky";

export default function Home(props) {
  // const [sponsorsOpen, setSponsorsOpen] = React.useState(true);

  // const [showSticky, setShowSticky] = React.useState(true);

  // const listenToScroll = () => {
  //   let heightToHideFrom = 200;
  //   const winScroll =
  //     document.body.scrollTop || document.documentElement.scrollTop;

  //   if (winScroll > heightToHideFrom) {
  //     showSticky && // to limit setting state only the first time
  //       setShowSticky(false);
  //   } else {
  //     setShowSticky(true);
  //   }
  // };

  // React.useEffect(() => {
  //   window.addEventListener("scroll", listenToScroll);
  //   return () => window.removeEventListener("scroll", listenToScroll);
  // });

  // function close() {
  //   setSponsorsOpen(false);
  //   setTimeout(() => {
  //     document.getElementById("sponsors--inner").style.animation =
  //       "fade-out 0.1s forwards";
  //   }, 10);
  // }
  // const [enter, setEnter] = React.useState(false);

  // let location = useLocation();
  // let displayLanding = true;
  // if (location.state.landing !== null) {
  //   displayLanding = location.state.landing;
  // }

  const postItems = posts.map((post, index) => {
    return (
      <Announcement
        key={index}
        title={post.title}
        body={post.body}
        img={post.img}
        links={post.links}
        date={post.date}
      />
    );
  });

  // const landing = <LandingPage onEnter={onEnter} />;

  const page = (
    <div className="home-outer-page">
      <Header />

      {/* <SponsorsInner openState={sponsorsOpen} closeFunc={close} /> */}
      {/* <Sponsors />

      <div className="sponsors--gray" id="sponsors-gray"></div> */}

      {/* <SponsorsDiv open={sponsorsOpen} />
      <SponsorsInner openState={sponsorsOpen} closeFunc={close} /> */}
      {/* <img className="flower_both" src="/images/flower_both.png"></img> */}

      <div className="home-page">
        <div className="chappa-L-container">
          <img
            className="chappa-L"
            src="/images/assets/illust_chappaL.png"
            alt="test"
          ></img>
        </div>
        <div className="chappa-R-container">
          <img
            className="chappa-R"
            src="/images/assets/illust_chappaR.png"
            alt="test"
          ></img>
        </div>

        <div className="kane-container">
          <img
            className="kane"
            src="/images/assets/illust_kaneL.png"
            alt="test"
          ></img>
        </div>
        <div className="flowerCombo-container">
          <img
            className="flowerCombo"
            src="/images/assets/illust_flowerCombo.png"
            alt="test"
          ></img>
        </div>

        <div className="flowerHappi-container">
          <img
            className="flowerHappi"
            src="/images/assets/illust_flowerHappi.png"
            alt="test"
          ></img>
        </div>

        <div className="chuBlue-container">
          <img
            className="chuBlue"
            src="/images/assets/illust_chuBlue.png"
            alt="test"
          />
        </div>

        <div className="announcements--container" id="announcements">
          {postItems}

          {/* TODO: MAP THE CHAPPA */}
        </div>
      </div>
      {/* {!showSticky && <SponsorsSticky />} */}
    </div>
  );

  function onEnter() {
    setTimeout(() => {
      document.getElementById("landing").style.animation =
        "fade-out 1.5s forwards";
      // setDisplayLand(false);
    }, 20);

    props.setEnter(true);

    setTimeout(() => {
      props.setDL(false);
    }, 1000);

    // console.log("yuhhhh");

    // setTimeout(() => {
    //   displayToNone();
    //   console.log("here");
    // }, 1500);
  }

  // function displayToNone() {
  //   document.getElementById("landing").style.display = "none";
  // }

  // React.useEffect(() => {
  //   //if props.enter is true
  //   if (props.enter) {
  //     displayToNone();
  //   }
  // }, [props.enter]);

  // const [displayLand, setDisplayLand] = React.useState(false);

  // console.log(displayLand);

  return (
    <div className="HOME-CONTAINER">
      {props.dl && <LandingPage onEnter={onEnter} />}
      {props.enter && page}
      <Footer isHome={true} />
    </div>
  );
}
