import React from "react";
import Header from "../components/Header";
// import SponsorsSticky from "../components/SponsorsSticky";
import Footer from "../components/Footer";
import Event from "../components/Event";
import { schedule } from "../data/ScheduleData";
// import Card from "../components/Card";

export default function Schedule() {
  // console.log(schedule);
  const scheduleBlocks = schedule.map((day, index) => {
    const myEvents = day.events.map((event, index) => {
      const color = index % 2 === 0 ? "blue" : "white";
      return (
        <Event
          key={index}
          name={event.name}
          time={event.time}
          location={event.location}
          link={event.link}
          color={color}
        />
      );
    });
    return (
      <div key={index} className="event--day-block">
        <h2>{day.day}</h2>
        {myEvents}
      </div>
    );
  });

  // const text = [
  //   "Over the course of three days, participants will have the opportunity to attend 3 workshops with 16 professional taiko performers. Workshops will cover a vast array of subjects, from the fundamentals of taiko drumming, to body awareness, to traditional makeup and dance.",
  //   "ECTC will also be a space for educational enrichment. There will be a public screening of Finding HerBeat, a documentary on sisterhood among female and non-binary taiko players across North America and Japan, as well as open-forum discussions related to taiko and identity.",
  //   "The conference will conclude with a final concert, highlighting the diverse performance styles and talents of performing individuals and groups.",
  // ];

  return (
    <div>
      <Header />
      <div className="page">
        <h1>Schedule</h1>
        <div className="chappa-L-container">
          <img
            className="chappa-L"
            src="/images/assets/illust_chappaL.png"
            alt="test"
          ></img>
        </div>
        <div className="chappa-R-container">
          <img
            className="chappa-R"
            src="/images/assets/illust_chappaR.png"
            alt="test"
          ></img>
        </div>
        <div className="kane-container">
          <img
            className="kane"
            src="/images/assets/illust_kaneL.png"
            alt="test"
          ></img>
        </div>
        <div className="flowerCombo-container">
          <img
            className="flowerCombo"
            src="/images/assets/illust_flowerCombo.png"
            alt="test"
          ></img>
        </div>
        {/* <div className="workshop--container">
          {/* <Card name="This page is under construction." /> */}
        {/* <Card body={text} /> */}
        {/* </div> */}
        <div className="sched--outer">
          <div className="sched--container">{scheduleBlocks}</div>
        </div>
      </div>
      {/* <SponsorsSticky /> */}
      <Footer isHome={true} />
    </div>
  );
}
