import React from "react";
import Navbar from "./Navbar";
import Hamburger from "./Hamburger";
import { useNavigate } from "react-router-dom";

import "../styles/headernavbar.css";

export default function Header() {
  // const [displayNav, setDisplayNav] = React.useState(false);

  // function navbarFunc() {
  //   setDisplayNav((prev) => !prev);
  // }
  let navigate = useNavigate();

  return (
    <div className="header-container">
      <div className="header--inner" id="header">
        {/* {displayNav && <Navbar onClick={navbarFunc} />} */}
        <Navbar />
        <div
          className="navbar--gray"
          id="navbar-gray"
          onClick={() => {
            document.getElementById("navbar").style.animation =
              "close-nav 0.4s forwards";
            document.getElementById("navbar-gray").style.display = "none";
          }}
        ></div>

        <Hamburger
          onClick={() => {
            document.getElementById("navbar").style.animation =
              "open-nav 0.4s forwards";
            document.getElementById("navbar-gray").style.display = "block";
          }}
        />
        {/* <div
        className="hamburger-menu"
        onClick={() => {
          document.getElementById("navbar").style.animation =
            "open-nav 0.4s forwards";
          document.getElementById("navbar-gray").style.display = "block";
        }}
      >
        <h1>[menu]</h1>
      </div> */}
        <div
          className="logo--container"
          tabIndex={0}
          onClick={() => navigate("/", { state: { landing: false } })}
        >
          <img
            src="/images/logos/ectc-logo.png"
            className="logo--img"
            alt="test"
          ></img>
        </div>
        <div>
          <div className="header--top-right">
            <h4>2.16 - 2.18</h4>
            <h4>PROVIDENCE, RI</h4>
            <a
              className="register-text"
              href="/images/map.png"
              // href="https://tickets.brown.edu/arts/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=A2E99F7B-EA5A-4B23-ADBE-3092488A1B12"
              target="_blank"
              rel="noreferrer"
            >
              CAMPUS MAP
            </a>
          </div>
        </div>
      </div>
      <div className="register-now">
        <a
          href="/images/map.png"
          // href="https://tickets.brown.edu/arts/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=A2E99F7B-EA5A-4B23-ADBE-3092488A1B12"
          target="_blank"
          rel="noreferrer"
        >
          CAMPUS MAP
        </a>
      </div>
    </div>
  );
}
