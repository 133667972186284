import React from "react";
import "../styles/card.css";
import ReactMarkdown from "react-markdown";

export default function TaikoTalk(props) {
  const components = {
    a: ({ node, ...props }) => (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    ),
  };

  return (
    <div className="tt--container">
      <div className="tt--person">
        <div className="tt--img">
          <img src={props.img} alt={props.name} />
        </div>
        <div>
          <h2>{props.name}</h2>
          <ReactMarkdown components={components}>{props.bio}</ReactMarkdown>
        </div>
      </div>
      <div className="tt--session">
        <h3>{props.title}</h3>
        <h4 className="tt--loc">
          <a
            href={props.link}
            target="_blank"
            rel="noreferrer"
            className="workshop--loc-link"
          >
            {props.location ? props.location : "placeholder"}
          </a>
        </h4>
        <ReactMarkdown>{props.desc}</ReactMarkdown>
      </div>
    </div>
  );
}
