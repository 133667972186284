export const contact = [
  {
    id: "ectc",
    name: "ECTC 2024",
    link: "https://ectc2024.com/",
    logo: "/images/logos/ectc-crest-white.png",
    links: [
      {
        id: "email",
        href: "mailto: ectc2024@gmail.com",
        img: "/images/email.png",
        alt: "email icon",
      },
      {
        id: "insta",
        href: "https://www.instagram.com/ectc2024/",
        img: "/images/instagram.png",
        alt: "instagram icon",
      },
    ],
  },
  {
    id: "gendo",
    name: "Gendo Taiko",
    link: "https://gendotaiko.com/",
    logo: "/images/logos/gendo-logo-white.png",
    links: [
      {
        id: "email",
        href: "mailto: brown.taiko@gmail.com",
        img: "/images/email.png",
        alt: "email icon",
      },
      {
        id: "insta",
        href: "https://instagram.com/gendotaiko/",
        img: "/images/instagram.png",
        alt: "instagram icon",
      },
      {
        id: "fb",
        href: "https://www.facebook.com/gendotaiko/",
        img: "/images/facebook.png",
        alt: "facebook icon",
      },
    ],
  },
];
