export const workshops = [
  {
    id: 0,
    session: "One",
    workshops: [
      {
        id: "chizuko3",
        title: "Drumming to Give Spirit & Energy",
        person: "CHIZUKO ENDO",
        level: "Intermediate",
        loc: "MACMILLAN 117",
        body: "Learning a drum piece designed to give spirit and energy to those carrying a heavy burden.",
        location: "Granoff Center, Kooper Studio N430",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
      {
        id: "kenny2",
        title: "Creativity and Composition",
        person: "KENNY ENDO",
        body: "Workshop to learn and practice writing original compositions.",
        location: "Stephen Robert '62 Campus Center, Petteruti Lounge 201",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },
      {
        id: "uchiwa",
        title: "Uchiwa Movement",
        person: "MICHELLE FUJII & TORU WATANABE",
        body: "Uchiwa daiko (fan drums) offer the versatility and mobility to coordinate complex movement with sound. This workshop will teach lively choreography from UNIT SOUZOU’s compositions.",
        location: "The Met C",
        link: "https://www.google.com/maps/place/The+Met/@41.8270885,-71.4087759,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44517dfbe1461:0x8e4bfbb9ae285ecf!8m2!3d41.8270885!4d-71.4062063!16s%2Fg%2F11f0krfnb8?entry=ttu",
      },

      {
        id: "yuta1",
        title: "Beta Fundamentals",
        person: "YUTA KATO",
        body: "Gaining a fundamental understanding of playing fuse-uchi.",
        location:
          "Stephen Robert '62 Campus Center, Kasper Multipurpose Room 040",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },
      {
        id: "franco2",
        title: "Katsugi Okedo Basics",
        person: "FRANCO IMPERIAL",
        body: "Workshop focused on basic grip, stance, strike technique, and movement for katsugi okedo.",
        location: "Smith Buonanno Hall 101",
        link: "https://www.google.com/maps?sca_esv=2fdcbf5d6771a9ce&output=search&q=smith+buonanno&source=lnms&entry=mc",
      },
      {
        id: "sumie",
        title: "Shamisen",
        person: "SUMIE KANEKO",
        body: "Learn to play the shamisen, a traditional, three-stringed Japanese instrument. Kaneko will hold two workshops on the same topic.",
        location: "The Met A",
        link: "https://www.google.com/maps/place/The+Met/@41.8270885,-71.4087759,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44517dfbe1461:0x8e4bfbb9ae285ecf!8m2!3d41.8270885!4d-71.4062063!16s%2Fg%2F11f0krfnb8?entry=ttu",
      },
      {
        id: "chieko1",
        title: "*Hana Hachijo",
        person: "CHIEKO KOJIMA",
        body: 'Workshop to learn Kojima\'s original piece "Hana Hachijo."',
        location: "TF Green Hall 205",
        link: "https://www.google.com/maps/place/Theodore+Francis+Green+Hall/@41.8244603,-71.3984153,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453af4bcfe63:0x737e7ce057816c3d!8m2!3d41.8244603!4d-71.3958404!16s%2Fg%2F11k5lvqgfh?entry=ttu",
      },
      {
        id: "use the force",
        title:
          '"Use the Force" -- Body Awareness and Ergonomics in Taiko Playing',
        person: "MANMAN MUI",
        body: "Exercises and drills to strengthen your connection with your body as you explore instruments. Approaching body movement efficiently to develop a strong and dynamic strike regardless of individual drumming style.",
        location: "Memorial Hall, RISD Tap Room",
        link: "https://www.google.com/maps/place/Memorial+Hall/@41.8263649,-71.4073358,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445f48cd91fa3:0x954d285d1d715ec9!8m2!3d41.8263649!4d-71.4073358!16s%2Fg%2F11svhlkr_z?entry=ttu",
      },

      {
        id: "young1",
        title: "Butoh and Taiko",
        person: "YOUNG PARK",
        level: "Beginner",
        body: "Started in 1950’s in Japan, Butoh style of movement became globalized in the 1970’s and 80’s.  The deeply internal imagery work and meditative process lends to bringing intensity and self-awareness to the Taiko practice.  No movement/dance experience necessary.  Workshop in barefeet.",
        location: "Lindemann Performing Arts Center, Movement Lab LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },

      {
        id: "stuart1",
        title: "*Tsubasa",
        person: "STUART PATON",
        body: "Using Paton’s piece “Tsubasa” to learn palm orientations, arm-joint alignment, proximity to the drum, foot and toe orientations, and knee movement.",
        location: "Alumnae Hall",
        link: "https://www.google.com/maps/place/Alumnae+Hall/@41.8296633,-71.4047352,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445230eba9fed:0x40de006467cd1fd2!8m2!3d41.8296633!4d-71.4021603!16s%2Fg%2F11xgcp7mz?entry=ttu",
      },

      {
        id: "mark2",
        title: "Bachi Maneuvers",
        person: "MARK H ROONEY",
        body: "Learn how to spin, throw and catch your bachi as a way to spice up your solo, performance or composition. Mark H will also reveal the secrets to making simple tricks appear more impressive and the deeper universal philosophies behind these (seemingly) superfluous skills.",
        location: "Lindemann Performing Arts Center, Performance Lab LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },
      {
        id: "Bounding",
        title: "*Bounding",
        person: "JOE SMALL",
        body: "Workshop for advanced players to learn Small’s original song “Bounding.” Played on a two-drum set involving Eitetsu-based methodology.",
        location: "Kassar House, Foxboro Auditorium",
        level: "Advanced",
        link: "https://www.google.com/maps/place/Kassar+House,+151+Thayer+St,+Providence,+RI+02912/@41.8252603,-71.4025946,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453b7cf8bc79:0x378799cf9435f9f5!8m2!3d41.8252603!4d-71.4000197!16s%2Fg%2F12hmv4c6r?entry=ttu",
      },
      {
        id: "ensemble",
        title: "Playing as an Ensemble",
        person: "SOH DAIKO",
        body: "Learning to practice and perform as a group.",
        location: "Sayles Hall",
        link: "https://www.google.com/maps/place/Sayles+Hall/@41.8262094,-71.4051015,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4450a8e1cc657:0xea3997d15854c2cc!8m2!3d41.8262054!4d-71.4025212!16s%2Fg%2F11h044sdh0?entry=ttu",
      },

      {
        id: "Tiffany1",
        title: "Odaiko Fundamentals",
        person: "TIFFANY TAMARIBUCHI",
        level: "Beginner",
        location: "Lindemann Performing Arts Center, William Riley Hall LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
        // body: "Learning and practicing upper-level odaiko techniques and patterns.",
      },
      // {
      //   id: "Tiffany3",
      //   title: "Soloing for People Who Hate Soloing but Have To Anyway",
      //   person: "TIFFANY TAMARIBUCHI",
      //   body: "A hands-on and simple, easy approach to improvisation, composition, and arrangement.",
      // },
      {
        id: "Karen1",
        title: "Taiko and Community Building",
        person: "KAREN S YOUNG",
        body: "Fostering relationsihps both inside and outside of the dojo.",
        location: "Alumnae Hall, Crystal Room",
        link: "https://www.google.com/maps/place/Alumnae+Hall/@41.8296633,-71.4047352,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445230eba9fed:0x40de006467cd1fd2!8m2!3d41.8296633!4d-71.4021603!16s%2Fg%2F11xgcp7mz?entry=ttu",
      },
      {
        id: "jen2",
        title: "Solo Coaching",
        person: "JENNIFER WEIR",
        body: "Discuss different approaches to creating solos that are fresh, engaging, and bring out your best self.",
        location: "Martinos Auditorium 110, Granoff Center",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
    ],
  },
  {
    id: 1,
    session: "Two",
    workshops: [
      {
        id: "chizuko1",
        title: "Channeling Energy in a Positive Way",
        person: "CHIZUKO ENDO",
        body: "Aimed at those teaching or interested in teaching children. A workshop on the best practices, games, and simple pieces to promote learning and creativity.",
        location: "Stephen Robert '62 Campus Center, Petteruti Lounge 201",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },
      {
        id: "kenny1",
        title: "Solid Shime Daiko Playing",
        person: "KENNY ENDO",
        body: "An overview of Edo Bayashi techniques.",
        location: "Granoff Center, Kooper Studio N430",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
      {
        id: "koshi",
        title: "Koshi",
        person: "MICHELLE FUJII & TORU WATANABE",
        body: "Koshi (“hips” in Japanese) is the key to understanding your power to play taiko. A workshop focused on movement drills and exercises to physically deepen your understanding of koshi from multiple perspectives.",
        location:
          "Stephen Robert '62 Campus Center, Kasper Multipurpose Room 040",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },
      {
        id: "franco1",
        title: "Yorokobi Taiko",
        person: "FRANCO IMPERIAL",
        body: "For the first time, San Jose Taiko’s 1974 piece “Yorokobi Taiko” will be shared as an open-source composition. A great arrangement for beginners.",
        location: "Lindemann Performing Arts Center, Performance Lab LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },
      {
        id: "sumie",
        title: "Shamisen",
        person: "SUMIE KANEKO",
        body: "Learn to play the shamisen, a traditional, three-stringed Japanese instrument. Kaneko will hold two workshops on the same topic.",
        location: "The Met A",
        link: "https://www.google.com/maps/place/The+Met/@41.8270885,-71.4087759,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44517dfbe1461:0x8e4bfbb9ae285ecf!8m2!3d41.8270885!4d-71.4062063!16s%2Fg%2F11f0krfnb8?entry=ttu",
      },
      {
        id: "yuta2",
        title: "Yodan Fundamentals",
        person: "YUTA KATO",
        level: "Intermediate",
        body: "Learning the basic movements of yodan, as well as patterns for practice and arrangement.",
        location: "TF Green Hall 205",
        link: "https://www.google.com/maps/place/Theodore+Francis+Green+Hall/@41.8244603,-71.3984153,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453af4bcfe63:0x737e7ce057816c3d!8m2!3d41.8244603!4d-71.3958404!16s%2Fg%2F11k5lvqgfh?entry=ttu",
      },
      {
        id: "chieko2",
        title: "How to Put on a Simple Yukata, Makeup for Kimono",
        person: "CHIEKO KOJIMA",
        location: "The Met C",
        link: "https://www.google.com/maps/place/The+Met/@41.8270885,-71.4087759,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44517dfbe1461:0x8e4bfbb9ae285ecf!8m2!3d41.8270885!4d-71.4062063!16s%2Fg%2F11f0krfnb8?entry=ttu",
      },
      {
        id: "quitplas",
        title: '"Quitiplas in the Pocket" -- Drills and Soloing with Polymeter',
        person: "MANMAN MUI",
        body: "An introduction to various drills and exercises to enhance listening skills, bachi control, and dexterity. A naname movement drill will be introduced to strengthen soloing vocabulary.",
        location: "Memorial Hall, RISD Tap Room",
        link: "https://www.google.com/maps/place/Memorial+Hall/@41.8263649,-71.4073358,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445f48cd91fa3:0x954d285d1d715ec9!8m2!3d41.8263649!4d-71.4073358!16s%2Fg%2F11svhlkr_z?entry=ttu",
      },
      {
        id: "young2",
        title: "Movement Creation for Taiko Composition",
        person: "YOUNG PARK",
        level: "Intermediate",
        body: "Taiko is in essence the blending of movement and drumming.  Explore how you can start to choreograph at, around, towards the drum…deepening your spatial relationship to the drums, as well as to your body.  You will have a chance to learn an excerpt from RAW’s new composition, “Where the heart.”  Workshop in barefeet.  Previous movement experience a plus.  Some floor work.",
        location: "Lindemann Performing Arts Center, Movement Lab LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },

      {
        id: "stuart2",
        title: "Hiryu Sandan Gaeshi",
        person: "STUART PATON",
        body: "Learning beta techniques, such as choreography, dynamics, and body awareness through “Hiryu Sandan Gaeshi.”",
        location: "Alumnae Hall",
        link: "https://www.google.com/maps/place/Alumnae+Hall/@41.8296633,-71.4047352,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445230eba9fed:0x40de006467cd1fd2!8m2!3d41.8296633!4d-71.4021603!16s%2Fg%2F11xgcp7mz?entry=ttu",
      },
      {
        id: "mark1",
        title: "Katsugi Okedo",
        person: "MARK H ROONEY",
        level: "Advanced",
        body: "Techniques for hitting the okedo, exercises to practice with, and tips on how to move while playing.",
        location: "Smith Buonanno Hall 101",
        link: "https://www.google.com/maps?sca_esv=2fdcbf5d6771a9ce&output=search&q=smith+buonanno&source=lnms&entry=mc",
      },
      {
        id: "Horizons",
        title: "*Horizons",
        person: "JOE SMALL",
        level: "Intermediate/Advanced",
        body: "Workshop for intermediate to advanced players to learn Small’s original song “Horizons.” Played using yoko uchi.",
        location: "Kassar House, Foxboro Auditorium",
        link: "https://www.google.com/maps/place/Kassar+House,+151+Thayer+St,+Providence,+RI+02912/@41.8252603,-71.4025946,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453b7cf8bc79:0x378799cf9435f9f5!8m2!3d41.8252603!4d-71.4000197!16s%2Fg%2F12hmv4c6r?entry=ttu",
      },
      {
        id: "stage presence",
        title: "Stage Presence",
        person: "SOH DAIKO",
        body: "Workshop to improve your energy and confidence during live performance.",
        location: "Sayles Hall",
        link: "https://www.google.com/maps/place/Sayles+Hall/@41.8262094,-71.4051015,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4450a8e1cc657:0xea3997d15854c2cc!8m2!3d41.8262054!4d-71.4025212!16s%2Fg%2F11h044sdh0?entry=ttu",
      },
      {
        id: "Tiffany",
        title: "Odaiko",
        person: "TIFFANY TAMARIBUCHI",
        level: "Intermediate",
        // body: "Learning and practicing upper-level odaiko techniques and patterns.",
        location: "Lindemann Performing Arts Center, William Riley Hall LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },
      {
        id: "jen1",
        title: "Stage Production",
        person: "JENNIFER WEIR",
        body: "Learn about the art of producing -- how to maximize your event from concept to the final bow. All levels of experience welcome!",
        location: "Smith Buonanno Hall 106",
        link: "https://www.google.com/maps?sca_esv=2fdcbf5d6771a9ce&output=search&q=smith+buonanno&source=lnms&entry=mc",
      },
      {
        id: "Karen2",
        title: "Taiko Games",
        person: "KAREN S YOUNG",
        body: "Teaching and learning taiko patterns and techniques -- with a playful spin.",
        location: "Alumnae Hall, Crystal Room",
        link: "https://www.google.com/maps/place/Alumnae+Hall/@41.8296633,-71.4047352,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445230eba9fed:0x40de006467cd1fd2!8m2!3d41.8296633!4d-71.4021603!16s%2Fg%2F11xgcp7mz?entry=ttu",
      },
    ],
  },
  {
    id: 2,
    session: "Three",
    workshops: [
      {
        id: "chizuko2",
        title: "Chudaiko Call & Answer",
        person: "CHIZUKO ENDO",
        level: "Intermediate",
        body: "Centered around call and response techniques on the chudaiko, this workshop is based on excerpts from Kenny Endo's song “Rites of Thundering.\"",
        location: "Lindemann Performing Arts Center, Performance Lab LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },
      {
        id: "kenny3",
        title: "Taiko Set",
        person: "KENNY ENDO",
        body: "Learning the power and technique of using multiple drums.",
        location:
          "Stephen Robert '62 Campus Center, Kasper Multipurpose Room 040",
        link: "https://www.google.com/maps/place/Stephen+Robert+'62+Campus+Center/@41.826859,-71.4057822,17z/data=!3m2!4b1!5s0x89e4453cd915d1b5:0x756bd79161649d5f!4m6!3m5!1s0x89e4453d211e9d73:0x4d8856ce8deb361e!8m2!3d41.826859!4d-71.4032073!16s%2Fg%2F1hhvnl_97?entry=ttu",
      },

      {
        id: "visual",
        title: "Visual Phrasing: Taiko Embodiment",
        person: "MICHELLE FUJII & TORU WATANABE",
        body: "Participants will create short phrases of taiko movement in an effort to utilize imagery and movement to develop intentional visual taiko.",
        location: "Alumnae Hall, Crystal Room",
        link: "https://www.google.com/maps/place/Alumnae+Hall/@41.8296633,-71.4047352,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445230eba9fed:0x40de006467cd1fd2!8m2!3d41.8296633!4d-71.4021603!16s%2Fg%2F11xgcp7mz?entry=ttu",
      },
      {
        id: "franco3",
        title: "San Jose Taiko's Swingposium",
        person: "FRANCO IMPERIAL",
        body: "A debut workshop on excerpts from the taiko portion of Swingposium, San Jose Taiko’s show about Japanese Internment. You will also learn about the way taiko can be used to tell Asian American stories and acquire information about getting involved in the production if it tours near you.",
        location: "Granoff Center, Kooper Studio N430",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
      {
        id: "chieko1",
        title: "*Hana Hachijo",
        person: "CHIEKO KOJIMA",
        body: 'Workshop to learn Kojima\'s original piece "Hana Hachijo."',
        location: "TF Green Hall 205",
        link: "https://www.google.com/maps/place/Theodore+Francis+Green+Hall/@41.8244603,-71.3984153,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453af4bcfe63:0x737e7ce057816c3d!8m2!3d41.8244603!4d-71.3958404!16s%2Fg%2F11k5lvqgfh?entry=ttu",
      },
      {
        id: "taiko fun",
        title: "Taiko FUNdamentals -- Cultivating Musicality",
        person: "MANMAN MUI",
        body: "Developing your musicality, creativity, and sense of originality through Orff Sculwerk’s approach of nurturing fun.",
        location: "Lindemann Performing Arts Center, Movement Lab LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },
      {
        id: "stuart3",
        title: "Drum Skinning -- Discussion",
        person: "STUART PATON",
        body: "Information sharing on skin prep, answering questions on skin stretching, replacement, and more.",
        location: "Smith Buonanno Hall 106",
        link: "https://www.google.com/maps?sca_esv=2fdcbf5d6771a9ce&output=search&q=smith+buonanno&source=lnms&entry=mc",
      },
      {
        id: "mark3",
        title: "Shin-En: Add a Song to Your Repertoire",
        person: "MARK H ROONEY",
        body: "Learn “Shin-En,” an open source song. After the workshop, anyone who participated gains access to Rooney’s “Shin-En” resource online and can add the song to their group’s repertoire.",
        location: "Alumnae Hall",
        link: "https://www.google.com/maps/place/Alumnae+Hall/@41.8296633,-71.4047352,17z/data=!3m1!4b1!4m6!3m5!1s0x89e445230eba9fed:0x40de006467cd1fd2!8m2!3d41.8296633!4d-71.4021603!16s%2Fg%2F11xgcp7mz?entry=ttu",
      },
      {
        id: "italicsMoontides",
        title: "*Moontides",
        person: "JOE SMALL",
        level: "Intermediate/Advanced",
        body: "Workshop for intermediate to advanced players to learn Small’s original song “Moontides.” Played using betta chudaiko choreography.",
        location: "Kassar House, Foxboro Auditorium",
        link: "https://www.google.com/maps/place/Kassar+House,+151+Thayer+St,+Providence,+RI+02912/@41.8252603,-71.4025946,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453b7cf8bc79:0x378799cf9435f9f5!8m2!3d41.8252603!4d-71.4000197!16s%2Fg%2F12hmv4c6r?entry=ttu",
      },
      {
        id: "ensemble",
        title: "Playing as an Ensemble",
        person: "SOH DAIKO",
        body: "Learning to practice and perform as a group.",
        location: "Sayles Hall",
        link: "https://www.google.com/maps/place/Sayles+Hall/@41.8262094,-71.4051015,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4450a8e1cc657:0xea3997d15854c2cc!8m2!3d41.8262054!4d-71.4025212!16s%2Fg%2F11h044sdh0?entry=ttu",
      },
      {
        id: "sumie",
        title: "Shamisen",
        person: "SUMIE KANEKO",
        body: "Learn to play the shamisen, a traditional, three-stringed Japanese instrument. Kaneko will hold two workshops on the same topic.",
        location: "Smith Buonanno Hall 101",
        link: "https://www.google.com/maps?sca_esv=2fdcbf5d6771a9ce&output=search&q=smith+buonanno&source=lnms&entry=mc",
      },
      {
        id: "Tiffany2",
        title: "Good Pain vs Bad Pain",
        person: "TIFFANY TAMARIBUCHI",
        body: "Ways to prevent life-altering, taiko-related injuries. A workshop on exploring the most dangerous playing styles and why some things shouldn’t hurt.",
        location: "Lindemann Performing Arts Center, William Riley Hall LL3",
        link: "https://www.google.com/maps/place/The+Lindemann+Performing+Arts+Center/@41.8281631,-71.4047498,17z/data=!3m1!4b1!4m6!3m5!1s0x89e44509b20b616b:0xb80dd252e438e180!8m2!3d41.8281631!4d-71.4021749!16s%2Fg%2F11hv5lwdsv?entry=ttu",
      },
      {
        id: "jen2",
        title: "Solo Coaching",
        person: "JENNIFER WEIR",
        body: "Discuss different approaches to creating solos that are fresh, engaging, and bring out your best self.",
        location: "Granoff Center, Martinos Auditorium 110",
        link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
      },
    ],
  },
  // {
  //   id: 1,
  //   session: "Two",
  //   workshops: [
  //     {
  //       id: 0,
  //       title: "Channeling Energy in a Positive Way",
  //       person: "CHIZUKO ENDO",
  //       body: "for those teaching or interested in teaching children; best practices, games, simple pieces that promote learning and creativity",
  //     },
  //     {
  //       id: 1,
  //       title: "Shime Daiko (Edo Bayashi techniques)",
  //       person: "KENNY ENDO",
  //       body: `yay\nyay`,
  //     },
  //   ],
  // },
];

export const leaders = [
  {
    id: "chizuko",
    img: "/images/workshopleaders/ChizukoEndo.jpg",
    name: "Chizuko Endo",
    description: [
      "Chizuko Endo began her taiko journey with San Francisco Taiko Dojo in 1978 then OEdo Sukeroku Taiko, the Kenny Endo Taiko Ensemble, and Taiko Center of the Pacific. She is co-founder and managing director of Taiko Center of the Pacific, a school of traditional and contemporary taiko. Additionally, she teaches taiko in public and private schools as well as classes for the Deaf and Hard of Hearing. She has performed throughout the US and Japan, as well as in Canada, Europe, Argentina, Malaysia, and Australia. Chizuko was one of 18 female taiko players from North America and Japan selected to participate in the HERbeat taiko residency and concert. Her piece, Yamamba, featuring a mask she carved, was one of the pieces showcased in the unprecedented concert. In the numerous concerts she has produced, high-ranking taiko groups and musicians have been introduced, raising the awareness and appreciation for high quality taiko.",
    ],
    confirmed: "yes",
  },
  {
    id: "kenny",
    img: "/images/workshopleaders/KennyEndo.jpg",
    name: "Kenny Endo",
    description: [
      "Kenny Endo is at the vanguard of the taiko genre, continuing to explore new possibilities for this ancient Japanese instrument.  A performer, composer, and teacher, Kenny is a consummate artist, blending taiko with original music through collaborations with artists from around the world.  In 2022, Kenny toured across the USA performing concerts, conducting workshops, and lectures.  He recently received the Hompa Hongwanji Living Treasures of Hawaii Award.  In January 2022, Kenny was awarded the United States Artists award.",
    ],
    confirmed: "yes",
  },
  {
    id: "michelle and toru",
    img: "/images/workshopleaders/MichelleToru.jpg",
    name: "Michelle Fujii & Toru Watanabe",
    description: [
      "Unit Souzou Co-Directors, Michelle Fujii and Toru Watanabe, build creative, imaginative works while honoring the history and roots of the taiko art form. They met at Warabi-za, Japan’s foremost traditional folk dance troupe located in Northern Japan.  Michelle was awarded the prestigious Bunka-cho fellowship by the Japanese government, and Toru was a professional company member who appeared in six original musical productions and taught within Warabiza’s in-house residency program for youth. Through their 25 years of professional experience, both are recognized as relentless innovators in the North American taiko community. Their newest work, Constant State of Otherness, is a National Performance Network, MAPFund, and New England Foundation for the Arts funded project, which centers on the historical and divisive ways that othering has pervasively affected our communities.",
    ],
    confirmed: "yes",
  },

  {
    id: "Franco",
    img: "/images/workshopleaders/FrancoImperial.jpg",
    name: "Franco Imperial",
    description: [
      "Franco Imperial is a member of the Audition Process Class of 1998. He joined the Artistic Staff in 2000 and became Artistic Director in 2011. Franco has composed over 20 original works for SJT and has co-created and produced collaborations with artists such as Abhinaya Dance Company (“Synergy” which won the 2011 Isadora Duncan Dance Award for Outstanding Achievement in Music/Sound/Text ), 5-time Grammy Award-nominee John Santos, NEA Heritage Fellow Danongan Kalanduyan, artist/teacher Dan Sabanovich, The Bangerz (Ji: A Common Pulse), Epic Immersive (Swingposium, Japantown Immersive), and Aswat Ensemble (Echoes Into The Future). He is a member of the Multicultural Arts Leadership Institute (MALI) and has led workshops for the North American Taiko Conference, European Taiko Conference, Intercollegiate Taiko Invitationals, and Calgary Taiko Gathering. In 2010 he became an Alliance for California Traditional Arts (ACTA) Apprenticeship Program recipient. In 2014 Franco was selected to be part of World Arts West’s NextGen Leadership Group for the San Francisco Ethnic Dance Festival. In 2019 he was selected by the City of San Jose Office Cultural Affairs to be a Creative License Ambassador. He has been selected as instructor for the 2019 Pacific Creative Placemaking Leadership Summit in Los Angeles, and a speaker on Audience Engagement in 2013 and 2019 at the Engage.next Conferences in San Jose.",
      "Franco has honed San Jose Taiko's unique style and created a voice for the current generation while honoring the group's 50+ year legacy. He leads SJT in artistic projects that align with three pillars: performing art as a catalyst to move audiences from awareness to action; performing art as a way to foster connection, cultural understanding, and widespread creativity; and performing art as a way to open hearts and minds, building a more just and equitable society.",
    ],
    confirmed: "yes",
  },
  {
    id: "sumie",
    img: "/images/workshopleaders/Sumie.jpg",
    name: "Sumie Kaneko",
    description: [
      "Sumie Kaneko is a Japanese koto and shamisen player and vocal artist. In 2002 after studying Japanese traditional music at Tokyo National University of the Arts, Sumie moved to Boston and studied Jazz vocal at Berklee College of Music. She has performed at such prestigious venues as Carnegie Hall, Lincoln Center, Blue Note NY, TED talk, Google, Getty Center, Boston Ballet, and the Museum of Fine Arts, Boston.",
      "She is renowned for her ability to collaborate with a diverse array of visionary international artists and writers: Pulitzer Winner Paula Vogel, composer Evan Ziporyn, taiko artist Kenny Endo and Kaoru Watanabe, and American Ballet Theater principal dancers Isabella Boylston and James Whiteside.",
      "Sumie tours overseas as a cultural exchange artist. She has performed in over 20 countries. Also as an educator, she has lectured at large academic scenes including: Harvard University, Princeton University, Massachusetts Institute of Technology, Texas A&M University, and Aoyama Gakuin University as a renowned international artist.",
    ],
    confirmed: "yes",
  },
  {
    id: "yuta", // CONFIRMED !!
    img: "/images/workshopleaders/yuta_headshot.jpg",
    name: "Yuta Kato",
    description: [
      "Yuta Kato, a California native from a Japanese-speaking family, discovered his passion for taiko through Kagami-Kai, a local group. He began formal taiko training at San Francisco Taiko Dojo at age 10. Throughout his journey, he joined various taiko groups like UCLA Kyodo Taiko, Nihon Taiko Dojo, and professional ensembles like TAIKOPROJECT, ON Ensemble, and more. From 2007 to 2011, he lived in Japan to learn traditional Japanese music. In 2011, he coordinated the North American Taiko Conference and later served as Principal at the Los Angeles Taiko Institute (LATI) for nearly a decade. Currently, he freelances as a taiko instructor and performs with UnitOne.",
    ],
    confirmed: "yes",
  },
  {
    id: "chieko",
    img: "/images/workshopleaders/chieko_kojima_headshot.png",
    name: "Chieko Kojima",
    description: [
      "Chieko Kojima first encountered Japanese folk dancing when she moved to Sado Island in 1976 to join Sado no Kuni Ondekoza. She went on to become one of Kodo’s founding members in 1981. In addition to her work with Kodo, she also has an active solo career that includes projects such as “Yukiai,” where she seeks out new encounters and collaborations with artists and taiko groups within Japan and throughout the world. Kojima is known for her original dancing style during Kodo’s taiko-based performances, which is best exemplified by her vivid portrayal of the goddess Ameno-uzume in the first season of “Amaterasu” in 2006. She became a Kodo Distinguished Member in 2012. In that same year, she traveled to Europe as a Japan Cultural Envoy from the Agency for Cultural Affairs. Kojima was the director of the annual concert series “Kodo Special Performances on Sado Island” for four years, starting from its inaugural season in 2012. In recent years, Kojima has been traveling the world to perform, collaborate, and lead workshops, sharing her extensive experience in dances that are deeply rooted in Japanese culture and powerful, feminine taiko drumming. She actively teaches her signature piece, Hana Hachijo, to taiko enthusiasts around the world, which combines dance and taiko into one graceful, powerful, uniquely feminine performance. In 2019, Kojima’s performance career 40th anniversary was commemorated with “Kodo Sen no Mai,” a spectacular concert where she took to the stage with the Kodo ensemble and special guest Shunsuke Kimura.",
    ],
    confirmed: "yes",
  },
  {
    id: "manman",
    img: "/images/workshopleaders/ManMan.JPG",
    name: "ManMan Mui",
    description: [
      "Yeeman “ManMan” Mui (they/them) dedicated to artistic expression that centers cultivating connections that lead with compassion and honoring their truth as a Non-binary, Asian, Disabled multidisciplinary taiko artist. Most of their artistic and educational work is collaborative in nature. Fostering an inclusive, equitable, and creative community to unpack and heal from the trauma of being minoritized.",
      "ManMan’s work in their early 20s with Hong Kong cinema cultivated a passion of creating soundscape composition to represent their neurodivergence authentically. As a HongKonger - a colonial subject, ManMan pushes the bounds of taiko arts and resists colonialism by seeking, and prioritizing elements of their heritage. They composed original taiko music, and directed the music production for award-winning documentary Blurring the Color Line: Chinese in the Segregated South in 2021.",
      "ManMan has a significant body of curriculums that center physical and mental wellness, including Taiko Together, Rhythmic Flow Taiko - Parkinson’s Friendly Taiko Class, and Makoto Taiko Senior Program. Currently working as a Teaching Artist in Grand Vision Foundation’s Meet the Music Program that serves the minoritized community in South LA. ManMan co-created a neurodiversity advocacy school program titled “Listening into Silence” with taiko artist Carrie Alita Carter.",
    ],
    confirmed: "yes",
  },
  {
    id: "young",
    img: "/images/workshopleaders/YoungPark.jpg",
    name: "Young Park",
    description: [
      "Young was born in South Korea and immigrated to Boston Massachusetts when she was 7-years old. She began studying music (viola) at the age of ten, attending New England Conservatory, Prep Division and Walnut Hill School of Performing Arts.",
      "She attended Eastman School of Music and then transferred to Oberlin College, where she also studied modern dance. After receiving her bachelor’s degree in Music from Oberlin College, she pursued further music and dance studies at University of Michigan, where she received her master’s degree. In 1992, she co-founded SAFMOD (Cleveland, OH), a multi-disciplinary performing arts group that focused on the creation of original dance, music and visual art. As Artistic Director of SAFMOD from 1993-2004, she created numerous original dance choreography, influenced by movement styles like Butoh, Contact Improv, capoeira and stilting.",
      "She joined RAW Taiko in 2007, became Artistic Director in 2017. She is also the Festival Director of Toronto Taiko Festival.",
    ],
    confirmed: "yes",
  },
  {
    id: "stuart",
    img: "/images/workshopleaders/StuartPaton.jpg",
    name: "Stuart Paton",
    description: [
      'Stuart Paton, Founder and Artistic Director of Burlington Taiko, spent most of his childhood in Japan, from age nine months through eighteen years. His earliest exposure to taiko included a first-grade fascination with the drums at an Obon celebration in Tokyo, and learning "Matsuri Daiko" from the composer of the score for his high school drama production. His formal study of taiko began in 1984 during a summer apprenticeship with Grandmaster Seiichi Tanaka, the founder of the first taiko group in North America (San Francisco Taiko Dojo), and he founded the Burlington Taiko Group in 1986 not long after settling in Vermont.',
      "His other hobbies include: reading sci-fi/fantasy/spy fiction, salsa and folkloric dancing, walking through marketplaces, yard sales, and antique shops, and composing new percussion pieces. He is also an active student, teacher, and performer of Cuban and Haitian congas and djembe.",
    ],
    confirmed: "yes",
  },
  {
    id: "mark",
    img: "/images/workshopleaders/MarkRooney.jpeg",
    name: "Mark H Rooney",
    description: [
      "The world’s most dangerous half-Japanese, half-Scottish solo improvisational taiko artist” has been sharing taiko for over 25 years, creating performances, workshops and classes with an emphasis on connection, reaction and interaction. Mark has taught taiko to thousands of students and worked with dozens of groups in the U.S., Japan and Europe. He has taught workshops at the North American Taiko Conference, the European Taiko Conference, the Toronto Taiko Festival, and every East Coast Taiko Conference since the first one in 2011.",
    ],
    confirmed: "yes",
  },
  {
    id: "joe",
    img: "/images/workshopleaders/JoeSmall.jpg",
    name: "Joe Small",
    description: [
      "Joe Small is a taiko artist, choreographer (or ‘taikographer’), Assistant Professor of Dance at Swarthmore College, and director of Swarthmore Taiko Ensemble.",
      "Joe has lived nearly a decade in Japan to pursue taiko, including as a Fulbright Scholar and a Kodo apprentice. Since 2012 Joe has been a disciple of pioneering taiko artist Eitetsu Hayashi and sole non-Japanese member of his ensemble, Fu-Un no Kai. Joe has also been a guest artist for Australia's premiere ensemble Taikoz since 2015, appearing most recently in their 2023 season.",
      "Joe has created and performed taiko for a variety of productions involving contemporary dance, puppetry, theatre, and heavy metal -- as well as his original full-length works, Spall Fragments (2015-16), and Moontides (2023, a collaboration between Kizuna Dance and Swarthmore Taiko Ensemble). Joe has performed and taught throughout the USA, Japan, Australia, Canada, Hong Kong, Spain, and Switzerland.",
      "Joe is a 2005 graduate of Swarthmore College (BA, Dance) and a 2015 graduate of UCLA (MFA, Dance).",
    ],
    confirmed: "yes",
  },
  {
    id: "soh daiko",
    img: "/images/workshopleaders/SohDaiko.jpg",
    name: "Soh Daiko",
    description: [
      "Established in 1979, Soh Daiko was the first taiko drumming group on the East Coast of the U.S. and is based in New York City. The group performs a variety of original compositions and arrangements that, in addition to the drums, incorporate accessory instruments such as bamboo flutes, gongs, African shekere, and more.",
      "Throughout its 40+ years, Soh Daiko has received critical acclaim from The New York Times, The San Francisco Chronicle, and Dance Magazine. The group has also been featured on Public Television’s Sesame Street, Reading Rainbow, National Public Radio’s All Things Considered, and NBC’s Today Show, and with artists such as Korn, Rob Thomas, and Post Malone. Soh Daiko regularly performs at New York City cultural festivities such as Carnegie Hall’s Citywide Concert Series, Japan Day Festival in Central Park, various cherry blossom festivals, and many more in the tri-state area and beyond.",
    ],
    confirmed: "yes",
  },

  {
    id: "tiffany",
    img: "/images/workshopleaders/TiffanyTamaribuchi.png",
    name: "Tiffany Tamaribuchi",
    description: [
      "Tiffany Tamaribuchi, an internationally recognized taiko master, has achieved a level of acclaim in trailblazing fashion. In the traditional taiko realm of Japanese born and trained male performers, she creates a new powerful voice with her multicultural heritage, youth, and feminine perspective. Ms. Tamaribuchi’s determination and perseverance, through long hours of grueling practice in her initial studies, transformed her sense of life’s possibilities, which she now does for others.",
    ],
    confirmed: "yes",
  },
  {
    id: "jennifer",
    img: "/images/workshopleaders/JenWeir.jpg",
    name: "Jennifer Weir",
    description: [
      "Jennifer Weir is the Executive Director of TaikoArts Midwest, Artistic Director of Enso Daiko, and has been actively performing, creating, producing, and teaching taiko drumming for over 25 years. She is the producer and featured artist of the FINDING HER BEAT documentary film.",
    ],
    confirmed: "yes",
  },
  {
    id: "karen",
    img: "/images/workshopleaders/KSY.jpeg",
    name: "Karen Susan Young",
    description: [
      "Karen (Boston, MA) leads and performs with the trio KM2 (Karen with Mica Rose and Mel Taing) as well as facilitates several community based taiko projects including Older and Bolder and Taiko and the Parks. Her community based work expanded while serving as a Boston Artist in Residence for the Mayor’s Office of Arts and Culture. She trained and performed as an original member of Odaiko New England under the founder Elaine Fong, then artistic director Mark Rooney from 1994-2009. She founded and directed the Asian women’s group, The Genki Spark from 2010-2020. Karen also collaborates on many taiko community projects including Taiko Margins (formerly Women and Taiko centering issues around gender) and Re-Imagining Taiko (addressing issues of racism). She is also the co-founder of the Brookline Cherry Blossom Festival, serves on the Advisory Council of TCA (Taiko Community Alliance), and has been an active leader building the North American Taiko Community since 1997.",
    ],
    confirmed: "yes",
  },

  // {
  //   id: "michelle",
  //   img: "/images/logos/ectc-logo-diamond-text.png",
  //   name: "Michelle Fujii",
  //   confirmed: "no",
  // },

  // {
  //   id: "kaoru",
  //   img: "/images/logos/ectc-logo-diamond-text.png",
  //   name: "Kaoru Watanabe",
  //   confirmed: "no",
  // },
  // {
  //   id: "toru",
  //   img: "/images/logos/ectc-logo-diamond-text.png",
  //   name: "Toru Watanabe",
  //   confirmed: "no",
  // },
];

export const taikoTalkData = [
  {
    id: "roy",
    name: "Roy Hirabayashi",
    img: "/images/taikotalk/hirabayashi.jpg",
    bio: `In 1973, Roy Hirabayashi co-founded and led as San Jose Taiko&#39;s artistic and executive
    director, North America&#39;s third oldest taiko group. Roy is an activist, composer,
    performer, teacher, and lecturer. He has toured internationally, performing the taiko and
    shinobue. He toured with SJT, Ondekoza and Kodo. He was an advisory member of the
    North American Taiko Conference from 1995 to 2013 and helped create the Taiko
    Community Alliance. In 2011, Roy and his wife PJ received the National Endowment of
    the Arts National Heritage Fellowship. He was selected for the 2017 US-Japan Council
    Japanese American Leadership Delegation. He is a founding board member of the
    Japantown Community Congress in San Jose Japantown, a senior fellow with the
    American Leadership Forum, and a 2015 John W. Gardner Leadership awardee. In
    2023, Roy celebrated 50 years of playing taiko, receiving the Japanese government&#39;s
    “Order of the Rising with Gold and Silver Rays.”  
    Contact Roy at [roy@taiko.org](mailto:roy@taiko.org) or [www.pjroytaiko.org](https://www.pjroytaiko.org/)`,
    title: "Why Do You Play Taiko?",
    location: "Smith Buonanno Hall 106",
    link: "https://www.google.com/maps?sca_esv=2fdcbf5d6771a9ce&output=search&q=smith+buonanno&source=lnms&entry=mc",
    description: `Roy Hirabayashi will share his 50-year taiko journey in North America, sharing the
    values and stories of his experiences while asking questions for you to ponder.  
    - Questions will be explored, such as why do you play taiko?   
    - What is being forgotten, ignored, or erased from the North American taiko history?  
    - Is taiko a cultural art form or another percussion instrument?  
    - Are you a taiko player or a percussionist playing on a Japanese drum?  
    - Are you playing taiko for cultural appreciation or cultural appropriation?`,
  },
  {
    id: "alan",
    name: "Alan Okada",
    img: "/images/taikotalk/okada.jpg",
    bio: `A muralist and graphic artist, Alan transitioned to a 34-year adventure in Corporate and
    Community Affairs at Citibank and Citigroup, including serving as VP of the Citi
    Foundation managing corporate philanthropy in more than 80 countries. He has served
    on the Boards of Kodo Arts Sphere America, the Asian American Arts Alliance and Asian
    American Pacific Islanders in Philanthropy.  
    Alan and his wife Mieko played taiko to accompany Obon celebrations during the mid-
    1970's and became founding members of Soh Daiko in 1979. Soh Daiko was the first
    taiko group on the east coast of the United States and one of the first 13 groups outside
    of Japan.     
    Alan served on the JACCC advisory committees for the first eight North American Taiko
    Conferences and on the advisory group for the JANM Big Drum exhibit. He served on the
    founding Board of the Taiko Community Alliance (TCA) and has been involved with the
    TCA NATCs as an advisor and presenter. He was a featured speaker at the 2020 World
    Taiko Conference and is honored to be participating in his 10 th ECTC.`,
    title: "Taiko Story",
    location: "Kassar House, Foxboro Auditorium",
    link: "https://www.google.com/maps/place/Kassar+House,+151+Thayer+St,+Providence,+RI+02912/@41.8252603,-71.4025946,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4453b7cf8bc79:0x378799cf9435f9f5!8m2!3d41.8252603!4d-71.4000197!16s%2Fg%2F12hmv4c6r?entry=ttu",
    description: `“A people without knowledge of their history is like a tree without roots”. An illustrated
    story of how taiko crossed an ocean, survived an existential crisis, reinvented itself
    through a dialogue between two cultures, and came to Providence, Rhode Island. Again.
    Including:  
     - Where taiko came from  
     - The half century that transformed the Pacific Rim  
     - Immigration, war and the incarceration of 120,000 Americans  
     - Reclaiming our cultural heritage. The 120 “OGs” that built a community with drums  
     - The rise of collegiate taiko and a national Asian American moment
     - A people’s art in a digital world`,
  },
  {
    id: "deborah",
    name: "Deborah Wong",
    img: "/images/taikotalk/wong.jpg",
    bio: `At the University of California, Riverside, Deborah Wong is an ethnomusicologist, Professor of Music,
    and chair of the Department of Ethnic Studies. She has written three books: *Louder and Faster: Pain,
    Joy, and the Body Politic in Asian American Taiko* (2019), *Speak It Louder: Asian Americans Making
    Music* (2004), and *Sounding the Center: History and Aesthetics in Thai Buddhist Ritual* (2001). She
    served as editor for Nobuko Miyamoto’s extraordinary memoir, *Not Yo’ Butterfly: My Long Song of
    Relocation, Race, Love, and Revolution* (2021). Active in public sector work at the national, state, and
    local levels, she serves on the boards for the Chinese American Museum DC and Great Leap. Her
    happiest hours of the week are spent going on air with her weekly radio show *Gold Mountain* for KUCR
    88.3 FM in Riverside.`,
    title: "Anger + Pain + Joy + Empowerment in Taiko: A Talking Space",
    location: "Martinos Auditorium 110, Granoff Center",
    link: "https://www.google.com/maps/place/Perry+and+Marty+Granoff+Center+for+the+Creative+Arts/@41.8282279,-71.4020801,19z/data=!3m1!4b1!4m6!3m5!1s0x89e445234536ba1b:0x4526e1e435ae6239!8m2!3d41.8282279!4d-71.4014364!16s%2Fg%2F11bwqbsf_q?entry=ttu",
    description: `As taiko players, we often experience a range of responses – from joy to irritation – when working with
    fellow musicians in our groups. In this discussion space, we will explore how our experiences of playing
    taiko are always personal AND political, individual AND social. I will share my own experiences as a
    member of the Taiko Center of Los Angeles and how gender, race/ethnicity, age, and sexual orientation
    shaped my ability to trust, value, and understand my colleagues. (If you like, read Chapter 5, “Taiko,
    Erotics, and Anger,” in my book *Louder and Faster* ahead of time – optional but not expected.) Our
    discussion will take place in a shared safe space of confidentiality. We will quickly conduct three
    interactive exercises.  
    (1) We will create a wordcloud of strong positive and negative emotions we have
    felt during rehearsals.  
    (2) We will share moments when we realized our taiko group’s activities were
    shaped by social and political forces.  
    (3) We will co-create a Spotify playlist of (taiko and non-taiko)
    songs that reflect our feelings about taiko, from longing to frustration to delight. In sum, we will consider
    how our ability to parse our emotional responses to taiko as both personal and political is key to how we
    can empower ourselves through it.`,
  },
];
