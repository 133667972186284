import React from "react";

import { contact } from "../data/ContactData";
import { useNavigate } from "react-router-dom";

export default function Footer(props) {
  let navigate = useNavigate();

  const contactItems = contact.map((contact, index) => {
    const links = contact.links.map((link, i) => {
      return (
        <li key={i}>
          <a
            className="contact-link"
            target="_blank"
            rel="noreferrer"
            href={link.href}
          >
            <img className="contact-icon" src={link.img} alt={link.alt} />
          </a>
        </li>
      );
    });
    return (
      <div key={index} className="contact-info">
        <img src={contact.logo} alt="test"></img>
        <a href={contact.link} target="_blank" rel="noreferrer">
          <h3>{contact.name}</h3>
        </a>
        <ul className="contact-footer">{links}</ul>
      </div>
    );
  });
  return (
    <div className={props.isHome ? "footer--home" : "footer--container"}>
      <div className="footer--sponsors">
        <h2 className>
          Thanks to our{" "}
          <span
            onClick={() => {
              return navigate("/sponsors");
            }}
          >
            sponsors
          </span>
          !
        </h2>
        <div className="footer--sponsors-logos-div">
          {/* <img
            className="sponsors--logo"
            src="/images/ectc-logo-diamond-text.png"
            alt="test"
          ></img>
          <img
            className="sponsors--logo"
            src="/images/ectc-logo-diamond-text.png"
            alt="test"
          ></img>
          <img
            className="sponsors--logo"
            src="/images/ectc-logo-diamond-text.png"
            alt="test"
          ></img>
          <img
            className="sponsors--logo"
            src="/images/ectc-logo-diamond-text.png"
            alt="test"
          ></img> */}
        </div>
      </div>

      {contactItems}

      {/* <div className="contact-info">
        <h3>Gendo Taiko</h3>
        <ul className="contact-footer">
          <li>
            <a
              className="contact-link"
              target="_blank"
              rel="noreferrer"
              href="mailto: ectc2024@gmail.com"
            >
              <img
                className="contact-icon"
                src="/images/email.png"
                alt="Email icon"
              />
            </a>
          </li>
          <li>
            <a
              className="contact-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/gendotaiko/"
            >
              <img
                className="contact-icon"
                src="/images/instagram.png"
                alt="instagram icon"
              />
            </a>
          </li>
          <li>
            <a
              className="contact-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/gendotaiko/"
            >
              <img
                className="contact-icon"
                src="/images/facebook.png"
                alt="facebook icon"
              />
            </a>
          </li>
        </ul>
      </div> */}
      <p>© Gendo Taiko 2023</p>
    </div>
  );
}
