import React from "react";
import "../styles/headernavbar.css";

export default function Hamburger(props) {
  return (
    <div
      className="hamburger--container"
      onClick={props.onClick}
      tabIndex={0}
      onKeyUp={(e) => {
        console.log("key event found");
        if (e.key === "Enter") {
          console.log("enter");
          props.onClick();
        }
      }}
    >
      <div className="hamburger--line"></div>
      <div className="hamburger--line"></div>
      <div className="hamburger--line"></div>
    </div>
  );
}
