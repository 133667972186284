export const restaurants = [
  {
    name: "[Heng Thai](https://www.hengpvd.com/home)",
    // url: "",
    hours: [
      "165 Angell St, Providence, RI 02906",
      "**Tue - Sun:** 11:30AM - 3PM, 4:30PM - 9:30PM, **Mon:** CLOSED",
    ],
  },
  {
    name: "[Harry's Bar & Burger](https://www.harrysbarburger.com/)",
    hours: [
      "121 North Main St, Providence, RI 02903",
      "**Mon-Sat:** 11:30AM - 10PM, **Sun:** CLOSED",
    ],
  },
  {
    name: "[Noodles 102](https://www.noodles102.com/)",
    hours: ["102 Ives St, Providence, RI 02906", "**Mon-Sat:** 5PM - 9PM"],
  },
  {
    name: "[Y Shabu Shabu](https://www.yshabushabu.com/)",
    hours: [
      "393 West Fountain St, Providence, RI 02903",
      "**Mon-Thurs, Sun:** 11:30AM - 11PM, **Fri-Sat:** 11:30AM - 12:00AM",
    ],
  },
  {
    name: "[Mokban Korean Bistro](http://mokbankoreanbistro.dine.online/)",
    hours: [
      "217 Westminister St, Providence, RI 02903",
      "**Sun-Mon, Wed-Thurs**: 11:30AM - 3:30PM, 4:30PM - 10PM, **Fri-Sat:** 11:30AM - 3:30PM, 4:30 - 10:30PM, **Tue:** CLOSED",
    ],
  },
  {
    name: "[Not Just Snacks](https://notjustsnacks.com/entrees/)",
    hours: ["833 Hope St, Providence, RI 02906", "**Sun-Sat:** 11AM - 9PM"],
  },
  {
    name: "[Pho Horn's](https://www.phohornspawtucket.com/?utm_source=gmb&utm_medium=menu)",
    hours: ["50 Ann Mary St, Pawtucket, RI 02860", "**Sun-Sat:** 11AM - 10PM"],
  },
  {
    name: "[Brickway on Wickenden](https://places.singleplatform.com/brickway-on-wickenden/menu?ref=google)",
    hours: [
      "234 Wickenden St, Providence, RI 02903",
      "**Sun-Tue, Thurs-Sat**: 8AM - 2PM, **Wed:** CLOSED",
    ],
  },
  {
    name: "Aleppo Sweets",
    hours: [
      "107 Ives St, Providence, RI 02906",
      "**Sun:** 11AM - 8:30PM, **Wed-Thurs:** 11AM - 8:30PM, **Fri-Sat:** 11AM - 9:30PM",
    ],
  },
];

export const hotels = [
  {
    id: "hotel1",
    name: "Hotel Providence",
    // link: "https://www.hotelprovidence.com/",
    address: "139 Mathewson Street, Providence, RI",
    body: ["139 Mathewson Street, Providence, RI"],
  },
  {
    id: "hotel2",
    name: "Homewood Suites by Hilton Providence Downtown",
    // link: "https://gendotaiko.com/",
    body: ["5 Exchange St, Providence, RI 02903"],
  },
  {
    id: "hotel3",
    name: "Hampton Inn & Suites Providence Downtown",
    // link: "https://gendotaiko.com/",
    body: ["58 Weybosset St, Providence, RI 02903"],
  },
  {
    id: "hotel4",
    name: "Best Western PVD-Seekonk",
    // link: "https://gendotaiko.com/",
    body: ["45 Mink St, Seekonk, MA 02771 (*~15 min away by car*)"],
  },
  {
    id: "hotel5",
    name: "Hampton Inn Seekonk",
    body: ["1030 Fall River Ave, Seekonk, MA 02771 (*~10 min away by car*)"],
  },
  {
    id: "hotel6",
    name: "Quality Inn Seekonk-Providence",
    body: ["341 Highland Ave, Seekonk, MA 02771 (*~10 min away by car*)"],
  },
];

export const travels = [
  [
    `
  ## PARKING
  
  Please park at one of the [Brown University Visitor Parking Lots](https://transportation.brown.edu/parking/visitor-parking).

  Find details, including rates, in the link above.

  ![image](/images/posts/powerstparking.png)

  **Power St Parking Garage**

  ![image](/images/posts/richmondparking.png)

  **The Richmond Garage**

  `,
  ],
  [
    `
  ## GETTING TO PROVIDENCE

From Logan Airport in Boston:

- Take the Massachusetts Bay Transportation Agency (MBTA) Silver Line 1 to South Station. Find the Silver Line schedule [here](https://www.mbta.com/schedules/741/line).

- Take Amtrak or MBTA Commuter Rail to the Amtrak/MBTA PVD Station. MBTA Commuter Rail tends to be cheaper, but Amtrak has more departure time options. Find trains to Providence [here](https://www.mbta.com/schedules/CR-Providence/timetable).

- The train station is within walking distance of Brown University. If you prefer not to walk, we suggest getting to campus by taking a taxi or using a rideshare service.
  
From TF Green Airport:

- Take the Rhode Island Public Transportation Authority (RIPTA) bus Route 1 to Brown University. Find bus schedules [here](https://www.ripta.com/routes/1/).

- Take a taxi or use a rideshare service.

From Amtrak/MBTA PVD Station:

- The train station is within walking distance of Brown University. If you prefer not to walk, we suggest getting to campus by taking a taxi or using a rideshare service.
`,
  ],
];
