import React from "react";

export default function Event(props) {
  return (
    <div className={`event--container ${props.color}`}>
      <div className="event--time">
        <p>{props.time}</p>
      </div>
      <div className="event--name">
        <p>
          <b>{props.name}</b>
        </p>
      </div>

      <div className="event--loc">
        <p>
          <a
            className="event--link"
            href={props.link}
            target="_blank"
            rel="noreferrer"
          >
            {props.location}
          </a>
        </p>
      </div>
    </div>
  );
}
