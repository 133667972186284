import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function NavbarItem(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const currPath = location.pathname;

  const [displaySub, setDisplaySub] = React.useState(false);

  const subPageItems = props.sub?.map((data, index) => {
    return (
      <div>
        <p
          onClick={() => {
            // console.log("yo");
            // console.log(props.subPaths[index]);
            return navigate(`/${props.subPaths[index]}`);
          }}
        >
          {data}
        </p>
      </div>
    );
  });

  return (
    <div
      className={
        props.path === currPath ? "navbar--option-curr" : "navbar--option"
      }
      onMouseEnter={() => {
        setDisplaySub(true);
      }}
      onMouseLeave={() => {
        setDisplaySub(false);
      }}
      onClick={() => {
        if (props.name === "home") {
          return navigate("/");
        } else if ("/" + props.name === currPath) {
          props.closeNav();
        }
        navigate(`/${props.name}`);
      }}
    >
      <h3 className="navbar--option-text">{props.name.toUpperCase()}</h3>
      {displaySub && subPageItems}
    </div>
  );
}
