import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Card from "../components/Card";
import { hotels, restaurants, travels } from "../data/AccomodationsData";
import "../styles/accomodations.css";
// import SponsorsSticky from "../components/SponsorsSticky";

export default function Accommodations() {
  const [display, setDisplay] = React.useState(0);

  const hotelItems = hotels.map((hotel, index) => {
    return <Card key={index} name={hotel.name} body={hotel.body} />;
  });

  const foodItems = restaurants.map((food, index) => {
    return (
      <Card
        key={index}
        name={food.name}
        body={food.hours}
        url={food.url}
      ></Card>
    );
  });

  const travelItems = travels.map((travel, index) => {
    return <Card key={index} body={travel} />;
  });

  // const accomodationsPage = (
  //   <div className="accom">
  //     <div className="accomm--buttons">
  //       <div className="accomm-scroll-link">
  //         <a href="#housing">Housing</a>
  //       </div>
  //       <div className="accomm-scroll-link">
  //         <a href="#food">Food</a>
  //       </div>
  //       <div className="accomm-scroll-link">
  //         <a href="#travel">Travel</a>
  //       </div>
  //     </div>

  //     <div className="accomm--section" id="housing">
  //       <h1>Housing</h1>
  //       <h4 className="housing-h4">
  //         NOTE: Due to university policy, we are not allowed to have contracts
  //         with any hotels. As such, there are no discounts for the hotels
  //         listed.
  //       </h4>
  //       {hotelItems}
  //     </div>

  //     <div className="accomm--section" id="food">
  //       <h1>Food</h1>
  //       <h4 className="housing-h4">
  //         NOTE: We will provide lunch on Saturday and breakfast on Sunday!
  //       </h4>
  //       {foodItems}
  //     </div>
  //     <div className="accomm--section" id="travel">
  //       <h1>Travel</h1>
  //     </div>
  //   </div>
  // );

  const housingPage = (
    <div className="accomm--section" id="housing">
      <h1>Housing</h1>
      <h4 className="housing-h4">
        NOTE: Due to university policy, we are not allowed to have contracts
        with any hotels. As such, there are no discounts for the hotels listed.
      </h4>
      {hotelItems}
    </div>
  );

  const foodPage = (
    <div className="accomm--section" id="food">
      <h1>Food</h1>
      <h4 className="housing-h4">
        NOTE: We will provide lunch on Saturday and breakfast on Sunday!
      </h4>
      {foodItems}
    </div>
  );

  const travelPage = (
    <div className="accomm--section" id="travel">
      <h1>Travel</h1>
      {travelItems}
    </div>
  );

  function displayPage() {
    if (display === 0) {
      return housingPage;
    } else if (display === 1) {
      return foodPage;
    } else {
      return travelPage;
    }
  }
  return (
    <div className="accomodations">
      <Header />
      <div className="page">
        {/* <div className="workshop--container">
          <Card name="This page is under construction." />
        </div> */}
        <div className="ws--button-container">
          <button
            className={`ws--button${display === 0 ? "-select" : ""}`}
            onClick={() => setDisplay(0)}
          >
            Housing
          </button>
          <button
            className={`ws--button${display === 1 ? "-select" : ""}`}
            onClick={() => setDisplay(1)}
          >
            Food
          </button>
          <button
            className={`ws--button${display === 2 ? "-select" : ""}`}
            onClick={() => setDisplay(2)}
          >
            Travel
          </button>
        </div>

        {displayPage()}

        <div className="chappa-L-container">
          <img
            className="chappa-L"
            src="/images/assets/illust_chappaL.png"
            alt="test"
          ></img>
        </div>
        <div className="chappa-R-container">
          <img
            className="chappa-R"
            src="/images/assets/illust_chappaR.png"
            alt="test"
          ></img>
        </div>

        {/* <div className="kane-container">
          <img
            className="kane"
            src="/images/assets/illust_kaneL.png"
            alt="test"
          ></img>
        </div> */}

        {/* <div className="chuBlue-container">
          <img
            className="chuBlue"
            src="/images/assets/illust_chuBlue.png"
            alt="test"
          />
        </div> */}
        {/* <h1>Accomodations</h1> */}

        {/* {accomodationsPage} */}
      </div>

      <Footer isHome={true} />
    </div>
  );
}
