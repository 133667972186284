import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Schedule from "./pages/Schedule";
import About from "./pages/About";
import Accomodations from "./pages/Accomodations";
import Sponsors from "./pages/Sponsors";
import Workshops from "./pages/Workshops";
import Closed from "./pages/Closed";
import FAQ from "./pages/FAQ";

function App() {
  const [enter, setEnter] = React.useState(false);
  const [displayLand, setDisplayLand] = React.useState(true);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            enter={enter}
            setEnter={setEnter}
            dl={displayLand}
            setDL={setDisplayLand}
          />
        }
      />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/about" element={<About />} />
      <Route path="/accommodations" element={<Accomodations />} />
      <Route path="/vendors & sponsors" element={<Sponsors />} />
      <Route path="/workshops" element={<Workshops />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/closed" element={<Closed />} />
    </Routes>
  );
}

export default App;
