import React from "react";

export default function WorkshopCard(props) {
  //   const [expanded, setExpanded] = React.useState(props.person ? true : false);
  //   const imageItem = (
  //     <div className="announcement--img-container">
  //       <img
  //         className="announcement--img-container"
  //         src={props.img}
  //         alt="test"
  //       ></img>
  //     </div>
  //   );

  //   var text = props.body.replace(/\n/g, "<br />");

  //   const linkItems = props.links?.map((data, index) => {
  //     return (
  //       <div>
  //         <a key={index} href={data.href} target="_blank" rel="noreferrer">
  //           {data.text}
  //         </a>
  //       </div>
  //     );
  //   });
  //   const bodyItem = (
  //     <div>
  //       <p
  //         dangerouslySetInnerHTML={{
  //           __html: props.body?.replace(/\i/g, "<i />"),
  //         }}
  //       >
  //         {/* {props.body} */}
  //       </p>
  //     </div>
  //   );

  //   const expandText = "expand";
  //   const collapseText = "collapse";

  return (
    <div className="workshop--card">
      <h2
        dangerouslySetInnerHTML={{
          __html: props.title.replace(/\*/g, "<i />"),
        }}
      ></h2>
      <h2 className="workshop-leader">{props.person}</h2>
      <div className="workshop--tag-container">
        <h4 className="workshop--tag">
          {props.level ? props.level : "Open to all levels"}
        </h4>

        <h4 className="workshop--loc">
          <a
            href={props.link}
            target="_blank"
            rel="noreferrer"
            className="workshop--loc-link"
          >
            {props.location ? props.location : "placeholder"}
          </a>
        </h4>
      </div>

      <div>
        <p>{props.body}</p>
      </div>
    </div>
  );
}
