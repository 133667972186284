import React from "react";
// import SponsorsSticky from "../components/SponsorsSticky";

export default function Closed() {
  return (
    <div className="closed-div">
      <h1>Sorry, registration has closed due to reaching maximum capacity.</h1>
    </div>
  );
}
