import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SponsorsCard from "../components/SponsorsCard";
import { sponsors, vendors } from "../data/SponsorsData";
// import VendorInfo from "../components/VendorInfo";
// import Card from "../components/Card";

export default function Sponsors() {
  const sponsorsItems = sponsors.map((data, index) => {
    return (
      <SponsorsCard
        key={index}
        img={data.img}
        name={data.name}
        desc={data.desc}
        link={data.link}
      />
    );
  });

  const vendorsItems = vendors.map((data, index) => {
    return (
      <SponsorsCard
        key={index}
        img={data.img}
        name={data.name}
        link={data.link}
      />
    );
  });
  return (
    <div className="sponsors-page">
      <Header />
      <div className="page">
        <div className="chappa-L-container">
          <img
            className="chappa-L"
            src="/images/assets/illust_chappaL.png"
            alt="test"
          ></img>
        </div>
        <div className="chappa-R-container">
          <img
            className="chappa-R"
            src="/images/assets/illust_chappaR.png"
            alt="test"
          ></img>
        </div>
        <div className="kane-container">
          <img
            className="kane"
            src="/images/assets/illust_kaneL.png"
            alt="test"
          ></img>
        </div>
        <div className="flowerCombo-container">
          <img
            className="flowerCombo"
            src="/images/assets/illust_flowerCombo.png"
            alt="test"
          ></img>
        </div>

        <div className="flowerHappi-container">
          <img
            className="flowerHappi"
            src="/images/assets/illust_flowerHappi.png"
            alt="test"
          ></img>
        </div>

        <div className="chuBlue-container">
          <img
            className="chuBlue"
            src="/images/assets/illust_chuBlue.png"
            alt="test"
          />
        </div>

        {/* <Card name="This page is under construction." /> */}
        {/* <h1>VENDORS</h1>
        <VendorInfo /> */}
        <h1>VENDORS</h1>
        <div className="sponsors-cards--container">{vendorsItems}</div>
        <h1>SPONSORS</h1>
        <div className="sponsors-cards--container">{sponsorsItems}</div>
      </div>
      <Footer isHome={true} />
    </div>
  );
}
