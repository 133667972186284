import React from "react";
import ReactMarkdown from "react-markdown";

export default function Announcement(props) {
  const [expanded, setExpanded] = React.useState(props.person ? true : false);
  const imageItem = (
    <div className="announcement--img-container">
      <img
        className="announcement--img-container"
        src={props.img}
        alt="test"
      ></img>
    </div>
  );

  const components = {
    a: ({ node, ...props }) => (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    ),
  };

  const linkItems = props.links?.map((data, index) => {
    return (
      <div>
        <a key={index} href={data.href} target="_blank" rel="noreferrer">
          {data.text}
        </a>
      </div>
    );
  });

  const bodyItems = props.body?.map((text, index) => {
    if (index === 0) {
      return (
        <div key={index}>
          {/* <p key={index}>{text}</p> */}
          <ReactMarkdown components={components}>{text}</ReactMarkdown>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <p className="break"></p>
          {/* <p>{text}</p> */}
          <ReactMarkdown components={components}>{text}</ReactMarkdown>
        </div>
      );
    }
  });

  const bodyItem = (
    <div className="annc-card-body">
      {bodyItems}
      {props.links && <div className="annc--links">{linkItems}</div>}
    </div>
  );

  const expandText = "expand";
  const collapseText = "collapse";

  return (
    <div
      className={`announcement--card${props.person ? "" : " yes-hover"}`}
      tabIndex={0}
    >
      {props.img && imageItem}
      <h2>{props.title}</h2>
      {props.person && <h2 className="workshop-leader">{props.person}</h2>}
      {props.date && <p className="date-text">{props.date}</p>}

      {expanded && bodyItem}

      {!props.person && props.body && (
        <div
          className="expand-container"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <p>{expanded ? collapseText : expandText}</p>
        </div>
      )}
    </div>
  );
}
