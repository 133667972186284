export const posts = [
  {
    id: 13,
    title:
      "If you are entering MacMillan Hall, please come through the entrance on the Sciences Quad, facing the Sciences Library (the tall cement building).",
    date: "Posted on: Saturday, February 17th, 2024 6:35AM",
  },
  {
    id: 12,
    title: "[SCHEDULE CHANGE] Registration today begins at 4pm!",
    body: [
      "Apologies for the last minute change! Registration today (Friday, Feb 16) will now begin at 4pm in the same location.",
      "See the [schedule](/schedule) page for more details.",
    ],
    date: "Posted on: Friday, February 16th, 2024",
  },
  {
    id: 11,
    title: "Workshop assignments are out!! Link below:",
    body: ["See workshop assignments here."],
    date: "Posted on Monday, February 12th, 2024",
  },
  {
    id: 10,
    title:
      "Due to technical difficulties, Workshop assignments will be sent by EOD Friday, Feb 9!",
    date: "Updated on: Wednesday, February 7th, 2024",
  },
  {
    id: 9,
    title:
      "Final Concert tickets available! If you are a registrant, you do not need to buy a ticket.",
    body: [
      "Purchase a ticket to our final concert [here](https://tickets.brown.edu/arts/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=A2E99F7B-EA5A-4B23-ADBE-3092488A1B12).",
      "If you are a registrant and are also bringing friends or family, please have them purchase a ticket.",
    ],
    date: "Posted on: Friday, January 26th, 2024",
  },
  {
    id: 8,
    title: "Taiko with Toni is a vendor! Pre-order info here:",
    body: [
      "Pre-order custom [Taiko with Toni](https://taikowithtoni.com/) bachi bags for delivery to ECTC (no shipping charge), plus a special ECTC discount! **Only the first 20 pre-orders for custom bags will be accepted; pre-orders accepted until January 31, 2024.**",
      "Purchase of current bachi bags in stock also available at ECTC discount.",
      "Please contact Toni at taikotoni@yahoo.com or text 1-720-244-4306 for additional info and details!",
    ],
    img: "/images/posts/TaikoToni.png",
    date: "Posted on: Monday, January 15th, 2024",
  },
  {
    id: 7,
    title: "kaDON is a vendor! Pre-order info here:",
    body: [
      "Follow these instructions to preorder from [kaDON](https://kadon-103184.square.site/)!",
      "The deadline to preorder is **Sunday, February 4, 2024**.",
      "**1:** Go to [www.kadon.com/store](https://kadon-103184.square.site/)",
      "**2:** Place items in shopping cart.",
      "**3:** Upon checkout, enter coupon code **ECTC2024** (no space) to receive free shipping to ECTC site.",
      "**4:** Bring your receipt for proof of purchase to our booth at the ECTC Marketplace to pick up your order!",
      " ",
      "*NOTE*: Free shipping offer **excludes** the following items: Taiko (exception: Uchiwa Daiko w/ or w/o handles, kaDON TimbreTaiko), Taiko Stands, Taiko Parts, Taiko Cases, TaikoCareThis is a great opportunity to stock up on bachi, taiko, tabi, auxiliary and other taiko related items! If there are people in your group who aren't able to attend the conference, but you are willing to carry their gear back to them, that is totally acceptable too. If you need something that’s out of stock or not on our store website, please contact info@kadon.com right away.",
    ],
    img: "/images/posts/kadonvendor.jpg",
    date: "Posted on: Monday, January 8th, 2024",
  },
  {
    id: 6,
    title: "ECTC 2024 Registration Extended to January 15",
    body: [
      "Quick update that we have pushed back the registration deadline to **January 15, 2024**! Please register [here](/closed) before then!",
      "Happy new year! Excited to play with you all so soon!",
    ],
    img: "/images/posts/ectc_reg_ex.png",
    date: "Posted on: Saturday, January 6th, 2024",
  },
  {
    id: 5,
    title: "Asano Taiko US is a vendor! Pre-order info here:",
    body: [
      'If you would like to preorder anything from [Asano Taiko US](https://asano.us/), use the code "**ECTC2024**" at checkout on their [website](https://asano.us/).',
      "The deadline to preorder is **Wednesday, January 31st, 2024**! Steps to follow below:",
      "**1:** Go to [asano.us](https://asano.us/).",
      "**2:** Choose your products.",
      "**3:** At the cart, put in the discount code: ECTC2024",
      "**4:** Pick it up at ECTC <3",
    ],
    img: "/images/posts/asanovendor.png",
    date: "Posted on: Wednesday, December 6th, 2023",
  },
  {
    id: 4,
    title: "Final Concert Registration open!",
    body: [
      "Exciting news about our **Final Concert**! On **Saturday from 7-9:30 PM at the Lindemann Performing Arts Center**, we will be hosting a concert to showcase different taiko players and groups in attendance. We’d like to ask any registrant or professional who is interested in performing at the Final Concert to please fill out their respective form which can be found in our bio.",

      "This year's theme, **In Return**, emphasizes the future of taiko not only as a performing art but also a form of cultural expression and community-engaged pedagogy. As we emerge from the pandemic, ECTC works to reignite and revitalize critically-needed relationships between taiko groups.",

      "**Please fill out the form by January 8** ([Registrant Link](https://docs.google.com/forms/d/e/1FAIpQLSc8PntReZFibsa0hrAhJYOhms9Nhl_8IBf8F-eE0-toGsx3xg/viewform)) if you are interested in performing! We look forward to sharing and exploring everyone’s taiko stories!",

      "This event is part of Brown Arts IGNITE (@baibrownu on Instagram), a multi-year series of creative activations, interventions, and investigations produced by communities across Brown, Providence, the Rhode Island region, and beyond.",
    ],
    img: "/images/posts/ectc24_final concert reg.png",
    // links: [
    //   {
    //     id: "form1",
    //     text: "ECTC 2024 Final Concert Application Form [Registrants]",
    //     href: "https://docs.google.com/forms/d/e/1FAIpQLSc8PntReZFibsa0hrAhJYOhms9Nhl_8IBf8F-eE0-toGsx3xg/viewform",
    //   },
    //   {
    //     id: "form2",
    //     text: "ECTC 2024 Final Concert Application Form [Professionals]",
    //     href: "https://docs.google.com/forms/d/e/1FAIpQLSc0CySBzSkg-sURo_1avddMZN7AZ9u73HOTA9G_r3qqDcyT8w/viewform",
    //   },
    // ],
    date: "Posted on: Tuesday, December 5th, 2023",
  },
  {
    id: 3,
    title: "Reminder: Both paperwork and ticket required for ECTC registration",
    body: [
      "Please remember that BOTH the form below and a ticket are required for ECTC registration. If you have already purchased a ticket, please take care to fill out the form if you have not already!",
    ],
    links: [
      {
        id: "ectc paperwork",
        text: "ECTC 2024 Registration Paperwork",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSdfgE1tSys352BuqlE4O5VS6-ziOYzaAln3KepNDUwtAHA7cA/viewform",
      },
      {
        id: "ectc ticket",
        text: "ECTC 2024 Registration Link",
        href: "/closed",
      },
    ],
    date: "Posted on: Saturday, November 11th, 2023",
  },
  {
    id: 2,
    title: "How to Register",
    body: [
      "Registration for ECTC 2024 has opened! In order to register, please do the following:",
      "**1:** Go to the [ECTC Registration Link](/closed) on Brown Marketplace.",
      "**2:** Before checking out, please fill out the [ECTC Registration Paperwork Google form](https://docs.google.com/forms/d/e/1FAIpQLSdfgE1tSys352BuqlE4O5VS6-ziOYzaAln3KepNDUwtAHA7cA/viewform) linked in the Marketplace description.",
      "**3:** Once you have submitted your Google form, return to the Marketplace link to pay for your ticket.",
      "**4:** Wait to hear from ectc2024@gmail.com for updates!",
    ],
    // links: [
    //   {
    //     id: "registration",
    //     text: "ECTC Registration Link",
    //     href: "/closed",
    //   },
    //   {
    //     id: "paperwork",
    //     text: "ECTC Registration Paperwork Google form",
    //     href: "https://docs.google.com/forms/d/e/1FAIpQLSdfgE1tSys352BuqlE4O5VS6-ziOYzaAln3KepNDUwtAHA7cA/viewform",
    //   },
    // ],
    img: "/images/logos/ectc-logo-tile.png",
    date: "Updated on: Monday, December 4th, 2023",
  },
  {
    id: 1,
    title: "Get excited for ECTC 2024!",
    body: [
      "Gendo Taiko is thrilled to be hosting East Coast Taiko Conference 2024!",

      "We look forward to seeing you all on Brown/RISD campus February 16th - February 18th, 2024.",
    ],
    img: "/images/logos/ectc-logo-diamond-text.png",
    date: "Friday, November 10th, 2023",
  },
  // {
  //   id: 0,
  //   title:
  //     "omg remember when emily's bachi flew out of her hand during korekara",
  //   body: "the other one",
  // },
];

// export const sponsors = [
//   {
//     id: 0,
//     name: "NAME 1",
//     desc: "this is a description",
//     img: "/images/ectc-logo-diamond.png",
//   },
//   {
//     id: 1,
//     name: "NAME 2",
//     desc: "this is a description",
//     img: "/images/ectc-logo-diamond.png",
//   },
//   {
//     id: 2,
//     name: "NAME 3",
//     desc: "this is a description",
//     img: "/images/ectc-logo-diamond.png",
//   },
//   {
//     id: 3,
//     name: "NAME 3",
//     desc: "this is a description",
//     img: "/images/ectc-logo-diamond.png",
//   },
// ];

export const navData = [
  {
    name: "home",
  },
  {
    name: "about",
  },
  {
    name: "vendors & sponsors",
  },
  {
    name: "schedule",
  },
  {
    name: "workshops",
    subPages: ["Workshops", "Workshop Leaders"],
    subPageLinks: ["sponsors", "schedule"],
  },
  {
    name: "accommodations",
    subPages: ["Option 1", "Option 2"],
    subPageLinks: ["sponsors", "schedule"],
  },
  {
    name: "FAQ",
  },
];

export const about = {
  gendoMissionStatement: `Gendo Taiko is a contemporary Taiko drumming ensemble centered around celebrating diasporic Asian identities. Consisting of students from Brown University and the Rhode Island School of Design, the group plays across New England for events ranging from musical festivals to small gatherings. 

  As one of the earliest collegiate Taiko drumming groups established on the East Coast, Gendo Taiko (initially dubbed Brown Taiko) was founded at Brown University in the Fall of 2004 by Raiki Machida (Brown ’07) and Joshua Goldner (Brown ’05). Gendo Taiko, initially dubbed Brown Taiko, Gendo Taiko’s earliest songs came from Wadaiko Gensou (和太鼓 幻創), a semi-professional Taiko duo based in Miyage, Japan, with whom Machida had trained extensively. In the summer of 2006, Goldner and Machida hosted Wadaiko Gensou’s members Takao Handa and Satoko Nambu at Brown University to expand the group’s repertoire, hone its members’ techniques, and build a new set of drums.
  
  As the group grew in membership, it also and found developed its own unique musical styles and produced unique song arrangements., it  Over the years, Gendo Taiko has adopted a formal audition process, developed a Group Independent Study Project (GISP) to explore the history of Taiko, and is now creating in the course of developing a well-structured program of study for its members. Today, Gendo Taiko continues to expand its repertoire through new compositions and collaborates with the greater Taiko community by hosting and participating in annual conferences and invitationals. Gendo Taiko is proud to be located in the Providence community and seeks to use its platform to celebrate the Asian diaspora.
  `,
};

export const text = {
  test: `hello
  
  hello
  
  hello`,
};
// "home",
// "about",
// "sponsors",
// "schedule",
// "workshops",
// "accomodations",
